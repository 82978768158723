import React, { useEffect, useRef, useState } from "react";
import "../styles/style.css";
import SpeedDail from "./SpeedDail";
import GoogleTranslate from "./Google-translate";
import Footer from "./Footer";

const Menu = () => {
  const [showAnimation, setShowAnimation] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const checkPosition = () => {
      const element = elementRef.current;
      const elementPosition = element.getBoundingClientRect().top;
      const viewportHeight = window.innerHeight;

      // Example: Trigger animation when element is in the viewport
      if (elementPosition < viewportHeight) {
        setShowAnimation(true);
      } else {
        setShowAnimation(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", checkPosition);

    // Check position initially in case the element is already in view on initial load
    checkPosition();

    // Clean up
    return () => window.removeEventListener("scroll", checkPosition);
  }, []);

  const [scrolled, setScrolled] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const handleSubMenuClick = (index) => (e) => {
    e.preventDefault();
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  // Function to handle scroll event
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => setIsMenuOpen(!isMenuOpen);

  const [isVisible, setIsVisible] = useState(false);

  // Function to check the scroll position
  const checkScrollTop = () => {
    if (!isVisible && window.pageYOffset > 400) {
      setIsVisible(true);
    } else if (isVisible && window.pageYOffset <= 400) {
      setIsVisible(false);
    }
  };

  // Function to scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  });

  return (
    <div className="body-header1">
      <div>
        {/* MOBILE MENU */}
        {isActive && (
          <div className="mask-nav-2 is-active">
            {/* MENU */}
            <nav className="navbar navbar-2 nav-mobile">
              <div className="nav-holder nav-holder-2">
                <ul id="menu-menu-2" className="menu-nav-2">
                  <li
                    className={`menu-item  ${
                      activeSubMenu === 1 ? "menu-open" : ""
                    }`}
                  >
                    <a href="/">Home</a>
                  </li>
                  <li className="menu-item">
                    <a href="/menu">Menu</a>
                  </li>

                  <li className="menu-item">
                    <a href="/reservation">Reservation</a>
                  </li>
                  <li className="menu-item ">
                    <a href="/gallery">Gallery</a>
                  </li>

                  <li className="menu-item ">
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
            </nav>
            {/* /MENU */}
          </div>
        )}
        {/* /MOBILE MENU */}
        {/* HEADER */}
        <header id="header-1">
          <div className="headerWrap-1">
            <nav className="navbar-1">
              {/* TOP LEFT PAGE TEXT  */}
              <div className="top-location">
                <span className="info-txt">
                  <a href="/" style={{ position: "relative", bottom: "0" }}>
                    <img
                      // className="img-fluid"
                      src="images/Premier-chef-logo.png"
                      alt="logo"
                      style={{ height: "100px" }}
                    />
                  </a>{" "}
                </span>

                {/* <span className="info-txt">New York 70250</span>    */}
              </div>

              <div
                className={`nav-button-holder nav-btn-mobile inactive ${
                  isActive ? "active" : "inactive"
                }`}
                onClick={toggleMenu}
                style={{ marginTop: "16px" }}
              >
                {/* <span className="menu-txt" style={{ fontSize: "18px" }}>
                  MENU
                </span> */}
                <button type="button" className="nav-button">
                  <span
                    className="fa fa-bars"
                    style={{ color: "white", fontSize: "30px" }}
                  />
                </button>
              </div>
              <div className="nav-content-1">
                {/* LOGO */}
                <div className="logo-1">
                <a href="/" className="navbar-brand p-0">
                     <img
                        className="img-fluid"
                        src="images/Premier-chef-logo.png"
                        alt="logo"
                        style={{ height: "80px" }}
                      />
                  </a>
                </div>
                {/* MENU */}
                <div className="nav-holder nav-holder-1 nav-holder-desktop">
                  <ul id="menu-menu-1" className="menu-nav menu-nav-1" style={{display:"flex", justifyContent:"end"}}>
                    <li className="menu-item ">
                      <a href="/">Home</a>
                    </li>
                    <li className="menu-item current-menu-item">
                      <a href="/menu" onMouseEnter={handleMouseEnter}>
                        Menu
                      </a>
                    </li>
                    {/* menu-item-has-children  */}
                    <li className="menu-item">
                      <a href="/reservation">Reservation</a>
                    </li>
                    <li className="menu-item ">
                      <a href="/gallery">Gallery</a>
                    </li>

                    <li className="menu-item ">
                      <a href="/contact">Contact</a>
                    </li>
                  </ul>
                </div>
                {/* /MENU */}
              </div>
            </nav>
          </div>
          {/*headerWrap*/}
        </header>
        {/* /HEADER */}
      </div>

      {/* {isHovered && (
        <div
          ref={elementRef}
          className={`menu animated ${
            showAnimation ? "animatedFadeInUp fadeInUp" : ""
          }`}
          onMouseLeave={handleMouseLeave}
        >
          <nav className={`menu-content ${isMenuOpen ? "active" : ""}`}>
           
            <div className="menu-holder menu-1col header-menu-accordion">
              <h4>
                <span className="header-menu-title">HOT COFFEES</span>
              </h4>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width={400}
                    height={400}
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f12bc8af498d45ed92c5d6f1dac64062_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>

                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFE AMERICANO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Espresso shots topped with hot water{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/5c515339667943ce84dc56effdf5fc1b_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAPPUCCINO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Dark espresso lies in wait under milk foam{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/58db701349cb48738069e8c912e2b3ac_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CARAMEL MACCHIATO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Freshly steamed milk with vanilla-flavored syrup{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f4ec500b34624242b15c2d29e53f3c48_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFFE MOCHA</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $7.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Full-bodied espresso combined with mocha{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-holder menu-1col header-menu-accordion">
              <h4>
                <span className="header-menu-title">COLD COFFEES</span>
              </h4>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width={400}
                    height={400}
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f12bc8af498d45ed92c5d6f1dac64062_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>

                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFE AMERICANO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Espresso shots topped with hot water{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/5c515339667943ce84dc56effdf5fc1b_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAPPUCCINO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Dark espresso lies in wait under milk foam{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/58db701349cb48738069e8c912e2b3ac_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CARAMEL MACCHIATO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Freshly steamed milk with vanilla-flavored syrup{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f4ec500b34624242b15c2d29e53f3c48_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFFE MOCHA</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $7.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Full-bodied espresso combined with mocha{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-holder menu-1col header-menu-accordion">
              <h4>
                <span className="header-menu-title">BAKERY</span>
              </h4>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width={400}
                    height={400}
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f12bc8af498d45ed92c5d6f1dac64062_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>

                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFE AMERICANO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Espresso shots topped with hot water{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/5c515339667943ce84dc56effdf5fc1b_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAPPUCCINO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Dark espresso lies in wait under milk foam{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/58db701349cb48738069e8c912e2b3ac_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CARAMEL MACCHIATO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Freshly steamed milk with vanilla-flavored syrup{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f4ec500b34624242b15c2d29e53f3c48_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFFE MOCHA</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $7.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Full-bodied espresso combined with mocha{" "}
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      )} */}

      <section className="topSingleBkg topPageBkg">
        <div className="item-content-bkg">
          <div
            className="item-img"
            style={{
              backgroundImage:
                'url("images/top-headers/reservation-image.jpg")',
            }}
          />
          <div className="inner-desc">
            <h1 className="post-title single-post-title">Our Menu</h1>
            <span className="post-subtitle"> We love to hear from you </span>
          </div>
        </div>
      </section>

      {/* Menu Section  */}

      <section id="home-content-4 ">
        <div className="container ">
          <div className="row">
            <div className="col-md-12 alignc ">
              <ul className="row our-menu">
                <li className="col-lg-6 col-md-12">
                  <h4 className="menu-title-section">HOT COFFEES</h4>
                  <div id="starters" className="menu-section">
                    <div className="menu-holder menu-1col menu-accordion">
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width={400}
                            height={400}
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f12bc8af498d45ed92c5d6f1dac64062_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>

                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Americano</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 45</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Rich, bold, espresso-based hot coffee.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/ec519dd5642c41629194192cce582135_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Espresso</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 50</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Strong, rich, concentrated, intense, energizing,
                            aromatic.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/5c515339667943ce84dc56effdf5fc1b_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Capuccino</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 50</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Rich, frothy, warm, invigorating, creamy,
                            delightful.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/2a7651bc593044ba9043e90ddc20e3f1_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Latte</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 50</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Rich, creamy, warm, comforting, smooth, aromatic.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f4ec500b34624242b15c2d29e53f3c48_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Mocha</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 50</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Rich, chocolatey, warm, aromatic, comforting,
                            delicious.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/58db701349cb48738069e8c912e2b3ac_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <sp className="menu-title">Caramel Macchiato</sp>
                            <span className="menu-dots"></span>an
                            <span className="menu-price">฿ 50</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Sweet, rich, creamy, espresso, vanilla, warm.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="col-lg-6 col-md-12">
                  <h4 className="menu-title-section">ICE COFFEES</h4>
                  <div id="main-courses" className="menu-section">
                    <div className="menu-holder  menu-accordion">
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/ec519dd5642c41629194192cce582135_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Americano</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 50</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Bold, refreshing, cold, smooth, invigorating.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info2 animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/ec519dd5642c41629194192cce582135_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Espresso</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 55</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Rich, bold, refreshing, cold, invigorating.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info2 animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/5c515339667943ce84dc56effdf5fc1b_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Capuccino</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 55</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Creamy, frothy, chilled, espresso, refreshing.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info2 animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/2a7651bc593044ba9043e90ddc20e3f1_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Latte</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 55</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Rich, creamy, chilled coffee delight.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info2 animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f4ec500b34624242b15c2d29e53f3c48_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Mocha</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 55</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Rich, chocolatey, smooth, caffeinated, refreshing.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info2 animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/58db701349cb48738069e8c912e2b3ac_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">
                              Caramel Macchiato
                            </span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 55</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Sweet, creamy, espresso-infused iced coffee.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info2 animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="col-lg-6 col-md-12">
                  <h4 className="menu-title-section">FRAPPE</h4>
                  <div id="soups" className="menu-section">
                    <div className="menu-holder menu-1col menu-accordion">
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width={400}
                            height={400}
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8Lxi9Ex0MoUjN9xaoMtdbkwkU9zW2HlTkNg&s"
                            className
                            alt=""
                            title
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Capuccino</span>{" "}
                            <span className="menu-dots" />
                            <span className="menu-price">฿ 60</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Creamy, frothy, coffee-flavored, chilled, sweet.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix  last ">
                        <div className="menu-post-img">
                          <img
                            width={400}
                            height={400}
                            src="https://media.istockphoto.com/id/1400793713/photo/cafe-mocha-with-whip-cream.jpg?s=612x612&w=0&k=20&c=31IKA85NJUYdjkJzM-1aNner-FXYGpB2Rc0QIJ4eWSA="
                            className
                            alt=""
                            title
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Latte</span>{" "}
                            <span className="menu-dots" />
                            <span className="menu-price">฿ 60</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Coffee, milk, ice, blended, sweet.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix ">
                        <div className="menu-post-img">
                          <img
                            width={400}
                            height={400}
                            src="https://images.pexels.com/photos/11512983/pexels-photo-11512983.jpeg?auto=compress&cs=tinysrgb&w=600"
                            className
                            alt=""
                            title
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Mocha</span>{" "}
                            <span className="menu-dots" />
                            <span className="menu-price">฿ 60</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Mocha FRAPPE: rich, creamy, espresso delight.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix last">
                        <div className="menu-post-img">
                          <img
                            width={400}
                            height={400}
                            src="https://cooktoria.com/wp-content/uploads/2018/03/Caramel-Frappuccino-Starbucks-2.jpg"
                            className
                            alt=""
                            title
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">
                              Caramel Macchiato
                            </span>{" "}
                            <span className="menu-dots" />
                            <span className="menu-price">฿ 60</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Sweet, creamy, iced coffee delight.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                {/* <li className="col-lg-6 col-md-12">
                  <h4 className="menu-title-section">Desserts</h4>
                  <div id="desserts" className="menu-section">
                    <div className="menu-holder menu-1col menu-accordion">
                      <div className="menu-post clearfix ">
                        <div className="menu-post-img">
                          <img
                            width={400}
                            height={400}
                            src="images/menu/summer-berry.jpg"
                            className
                            alt
                            title
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">
                              Summer Berry and Coconut Tart
                            </span>{" "}
                            <span className="menu-dots" />
                            <span className="menu-price">฿ $12.00</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Raspberries, blackberries, blueberries, graham
                            cracker crumbs{" "}
                          </div>
                        </div>
                        <div ref={elementRef} className={`nutritional-info2 animated ${showAnimation ? 'animatedFadeInUp fadeInUp' : ''}`}>
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix  last ">
                        <div className="menu-post-img">
                          <img
                            width={400}
                            height={400}
                            src="images/menu/pumpkin-cookies.jpg"
                            className
                            alt
                            title
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">
                              Pumpkin Cookies Cream Cheese
                            </span>{" "}
                            <span className="menu-dots" />
                            <span className="menu-price">฿ $10.00</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Pumpkin, sugar, butter, eggs{" "}
                          </div>
                        </div>
                        <div ref={elementRef} className={`nutritional-info2 animated ${showAnimation ? 'animatedFadeInUp fadeInUp' : ''}`}>
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix ">
                        <div className="menu-post-img">
                          <img
                            width={400}
                            height={400}
                            src="images/menu/chocolate-cupcakes.jpg"
                            className
                            alt
                            title
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">
                              Double Chocolate Cupcakes
                            </span>{" "}
                            <span className="menu-dots" />
                            <span className="menu-price">฿ $7.00</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Chocolate, eggs, vanilla, milk{" "}
                          </div>
                        </div>
                        <div ref={elementRef} className={`nutritional-info2 animated ${showAnimation ? 'animatedFadeInUp fadeInUp' : ''}`}>
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Menu;
