import React, { useEffect, useRef, useState } from "react";
import "../styles/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import SpeedDail from "./SpeedDail";
import GoogleTranslate from "./Google-translate";
import Footer from "./Footer";
import { toast } from "react-toastify";
import { Alert } from "@mui/material";

const Home = () => {
  const [showAnimation, setShowAnimation] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const checkPosition = () => {
      const element = elementRef.current;
      const elementPosition = element.getBoundingClientRect().top;
      const viewportHeight = window.innerHeight;

      // Example: Trigger animation when element is in the viewport
      if (elementPosition < viewportHeight) {
        setShowAnimation(true);
      } else {
        setShowAnimation(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", checkPosition);

    // Check position initially in case the element is already in view on initial load
    checkPosition();

    // Clean up
    return () => window.removeEventListener("scroll", checkPosition);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    fade: true,
    // centerMode: true,
  };

  const [scrolled, setScrolled] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const handleSubMenuClick = (index) => (e) => {
    e.preventDefault();
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  // Function to handle scroll event
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => setIsMenuOpen(!isMenuOpen);

  const [isVisible, setIsVisible] = useState(false);

  // Function to check the scroll position
  const checkScrollTop = () => {
    if (!isVisible && window.pageYOffset > 400) {
      setIsVisible(true);
    } else if (isVisible && window.pageYOffset <= 400) {
      setIsVisible(false);
    }
  };

  // Function to scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  });

  const [showApprovalMessage, setShowApprovalMessage] = useState(false);

  // useEffect(() => {
  //   // Check if the approval message needs to be shown
  //   const reservationApproved = localStorage.getItem('reservationApproved');
  //   if (reservationApproved === 'approved') {
  //     setShowApprovalMessage(true);
  //     toast.success('Your request has been approved!', {
  //       position: "top-right",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     localStorage.removeItem('reservationApproved');
  //   }

  //   const reservationRejected = localStorage.getItem('reservationRejected');
  //   if (reservationApproved === 'rejected') {
  //     alert('Your request has been Rejected!');
  //     localStorage.removeItem('reservationRejected');
  //   }
  // }, []);

  const [initialLoad, setInitialLoad] = useState(true);

  // useEffect(() => {
  //   if (initialLoad) {
  //     const lastActionMessage = localStorage.getItem("lastActionMessage");
  //     if (lastActionMessage) {
  //       toast.success(lastActionMessage, {
  //         position: "top-right",
  //         autoClose: 20000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //       // localStorage.removeItem('lastActionMessage');
  //     }
  //     setInitialLoad(false);
  //   }
  // }, [initialLoad]);

  const [open, setOpen] = useState(true);

  // Function to call when the alert needs to be closed
  const handleClose = () => {
    setOpen(false);
  };

  // If 'open' is false, don't render the Alert
  if (!open) return null;

  return (
    <div className="body-header1">
      {/* {showApprovalMessage && (
      <Alert variant="filled" severity="success" onClose={handleClose}>
      Your request has been approved!
    </Alert>
      )} */}
      {/* MOBILE MENU */}
      <div>
        {/* MOBILE MENU */}
        {isActive && (
          <div className="mask-nav-2 is-active">
            {/* MENU */}
            <nav className="navbar navbar-2 nav-mobile">
              <div className="nav-holder nav-holder-2">
                <ul id="menu-menu-2" className="menu-nav-2">
                  <li
                    className={`menu-item  ${
                      activeSubMenu === 1 ? "menu-open" : ""
                    }`}
                  >
                    <a href="/">Home</a>
                  </li>
                  <li className="menu-item">
                    <a href="/Menu">Menu</a>
                  </li>

                  <li className="menu-item">
                    <a href="/reservation">Reservation</a>
                  </li>
                  <li className="menu-item ">
                    <a href="/gallery">Gallery</a>
                  </li>

                  <li className="menu-item ">
                    <a href="/Contact">Contact</a>
                  </li>
                </ul>
              </div>
            </nav>
            {/* /MENU */}
          </div>
        )}
        {/* /MOBILE MENU */}
        {/* HEADER */}
        <header id="header-1">
          <div className="headerWrap-1">
            <nav className="navbar-1">
              {/* TOP LEFT PAGE TEXT  */}
              <div className="top-location">
                <span className="info-txt">
                  <a href="/" style={{ position: "relative", bottom: "0" }}>
                    <img
                      // className="img-fluid"
                      src="images/Premier-chef-logo.png"
                      alt="logo"
                      style={{ height: "100px" }}
                    />
                  </a>{" "}
                  {/* <a href="/" className="navbar-brand p-0">
                    <h1>Premier Chef</h1>
                  </a> */}
                </span>

                {/* <span className="info-txt">New York 70250</span>    */}
              </div>
              {/* TOP RIGHT PAGE TEXT  */}
              {/* <div className="book-now">
                <ul className="header-social">
                  <li>
                    <a
                      className="header-social-facebook"
                      href="https://web.facebook.com/premierchefcatering"
                      target="_blank"
                    >
                      <i className="fab fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="header-social-twitter"
                      href="#"
                      target="_blank"
                    >
                      <i className="fab fa-line" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="header-social-instagram"
                      href="https://www.instagram.com/premierchefcatering/"
                      target="_blank"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                
                </ul>
              </div> */}

                {/* <div className="google">
            <GoogleTranslate/>
            </div> */}
                  {/* <li>
              <a>
              <GoogleTranslate/>
              </a>
            </li> */}

              {/* <div className="book-now">
                <span className="info-txt">Book Now</span>
                <span className="info-txt">+1-222-333-444</span>
              </div> */}
              {/* MOBILE BUTTON NAV  */}

              <div
                className={`nav-button-holder nav-btn-mobile inactive ${
                  isActive ? "active" : "inactive"
                }`}
                onClick={toggleMenu}
                style={{ marginTop: "13px" }}
              >
                {/* <span className="menu-txt" style={{ fontSize: "18px" }}>
                  MENU
                </span> */}
                <button className="nav-button">
                  <span
                    className="fa fa-bars"
                    style={{ color: "white", fontSize: "30px" }}
                  />
                </button>
              </div>
              <div className="nav-content-1">
                {/* LOGO */}
                <div className="logo-1">
                  <a href="/" className="navbar-brand p-0">
                     <img
                        className="img-fluid"
                        src="images/Premier-chef-logo.png"
                        alt="logo"
                        style={{ height: "80px" }}
                      />
                  </a>
                </div>


                {/* MENU */}
                <div className="nav-holder nav-holder-1 nav-holder-desktop">
                  <ul id="menu-menu-1" className="menu-nav menu-nav-1" style={{display:"flex", justifyContent:"end"}}>
                    <li className="menu-item current-menu-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="menu-item ">
                      <a href="/menu" onMouseEnter={handleMouseEnter}>
                        Menu
                      </a>
                    </li>
                    {/* menu-item-has-children  */}
                    <li className="menu-item ">
                      <a href="/reservation">Reservation</a>
                    </li>
                    <li className="menu-item ">
                      <a href="/gallery">Gallery</a>
                    </li>

                    <li className="menu-item ">
                      <a href="/contact">Contact</a>
                    </li>
                    {/* <li className="menu-item ">
                      <a><GoogleTranslate/></a>
                    </li> */}
                  </ul>
                </div>
                {/* /MENU */}
              </div>
            </nav>
          </div>
          {/*headerWrap*/}
        </header>
        {/* /HEADER */}
      </div>

      {/* {isHovered && (
        <div
          ref={elementRef}
          className={`menu animated ${
            showAnimation ? "animatedFadeInUp fadeInUp" : ""
          }`}
          onMouseLeave={handleMouseLeave}
        >
          <nav className={`menu-content ${isMenuOpen ? "active" : ""}`}>
            <div className="menu-holder menu-1col header-menu-accordion">
              <h4>
                <span className="header-menu-title">HOT COFFEES</span>
              </h4>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width={400}
                    height={400}
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f12bc8af498d45ed92c5d6f1dac64062_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>

                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFE AMERICANO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Espresso shots topped with hot water{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/5c515339667943ce84dc56effdf5fc1b_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAPPUCCINO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Dark espresso lies in wait under milk foam{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/58db701349cb48738069e8c912e2b3ac_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CARAMEL MACCHIATO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Freshly steamed milk with vanilla-flavored syrup{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f4ec500b34624242b15c2d29e53f3c48_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFFE MOCHA</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $7.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Full-bodied espresso combined with mocha{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-holder menu-1col header-menu-accordion">
              <h4>
                <span className="header-menu-title">COLD COFFEES</span>
              </h4>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width={400}
                    height={400}
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f12bc8af498d45ed92c5d6f1dac64062_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>

                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFE AMERICANO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Espresso shots topped with hot water{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/5c515339667943ce84dc56effdf5fc1b_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAPPUCCINO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Dark espresso lies in wait under milk foam{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/58db701349cb48738069e8c912e2b3ac_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CARAMEL MACCHIATO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Freshly steamed milk with vanilla-flavored syrup{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f4ec500b34624242b15c2d29e53f3c48_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFFE MOCHA</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $7.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Full-bodied espresso combined with mocha{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-holder menu-1col header-menu-accordion">
              <h4>
                <span className="header-menu-title">BAKERY</span>
              </h4>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width={400}
                    height={400}
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f12bc8af498d45ed92c5d6f1dac64062_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>

                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFE AMERICANO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Espresso shots topped with hot water{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/5c515339667943ce84dc56effdf5fc1b_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAPPUCCINO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Dark espresso lies in wait under milk foam{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/58db701349cb48738069e8c912e2b3ac_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CARAMEL MACCHIATO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Freshly steamed milk with vanilla-flavored syrup{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f4ec500b34624242b15c2d29e53f3c48_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFFE MOCHA</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">฿ $7.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Full-bodied espresso combined with mocha{" "}
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      )} */}

      <div
        className="slider-post slider-item-box-bkg"
        style={{ background: "black" }}
      >
        <Slider {...settings}>
          <div className="slider-img">
            <img
              decoding="async"
              src="images/slider/slide1.jpg"
              alt="Image 1"
              style={{ opacity: "1" }}
            />
            <div className="slider-caption">
              <div className="slider-text">
                <h1>Welcome</h1>
                <p>Premium food prepared by Premier Chef</p>
                <p>
                  {" "}
                  <a href="/reservation" className="view-more more-white">
                    Book now
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="slider-img">
            <img
              decoding="async"
              src="images/slider/slide2.jpg"
              alt="Image 2"
              style={{ opacity: "1" }}
            />
            <div className="slider-caption">
              <div className="slider-text">
                <h1>Welcome</h1>
                <p>Premium food prepared by Premier Chef</p>
                <p>
                  {" "}
                  <a href="/reservation" className="view-more more-white">
                    Book now
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="slider-img">
            <img
              decoding="async"
              src="images/slider/slide3.jpg"
              alt="Image 3"
              style={{ opacity: "1" }}
            />
            <div className="slider-caption">
              <div className="slider-text">
                <h1>Welcome</h1>
                <p>Premium food prepared by Premier Chef</p>
                <p>
                  {" "}
                  <a href="/reservation" className="view-more more-white">
                    Book now
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Slider>
        {/* <div className='hero-section'>
        <h2>Premier Chef Catering</h2>
        <p>Premier Chef: Exquisite Catering for Unforgettable Events.</p>
      </div> */}
        <div className="slider-caption">
          <div className="slider-text">
            {/* <h1>Welcome</h1> */}
            {/* <p>
              Discover and experience fine selection of international tastes
            </p> */}
            {/* <p>
              {" "}
              <a href="#" className="view-more more-white">
                Book now
              </a>
            </p> */}
          </div>
        </div>
      </div>
      {/* /HOME SLIDER */}

      {/* SECTION 1 */}
      <section id="home-content-1" className="home-widget home-features">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="small-feature">
                <div className="small-feature-icon">
                  <img
                    src="images/icons/groceries.png"
                    loading="lazy"
                    alt="groceries"
                    width={48}
                    height={48}
                    className
                  />
                </div>
                <div className="small-feature-desc">
                  <h5>Fresh Food</h5>
                  <p>
                    Fresh food is nutritious, flavorful, and harvested or
                    prepared shortly before consumption for optimal taste.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="small-feature">
                <div className="small-feature-icon">
                  <img
                    src="images/icons/food.png"
                    loading="lazy"
                    alt="groceries"
                    width={48}
                    height={48}
                    className
                  />
                </div>
                <div className="small-feature-desc">
                  <h5>Chef's Specials</h5>
                  <p>
                    Indulge in our Chef's Specials: innovative dishes crafted
                    with fresh, seasonal ingredients for a memorable dining
                    experience..
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="small-feature">
                <div className="small-feature-icon">
                  <img
                    src="images/icons/glass.png"
                    loading="lazy"
                    alt="groceries"
                    width={48}
                    height={48}
                    className
                  />
                </div>
                <div className="small-feature-desc">
                  <h5>Good Food</h5>
                  <p>
                    Deliciously crafted meals made with fresh, high-quality
                    ingredients to delight your taste buds.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /SECTION 1 */}

      {/* SECTION 2 */}
      <section id="home-content-2" className="home-widget">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="home-subtitle">History</h2>
              <h1 className="home-title margin-b24 title-headline">About Us</h1>
              <div className="title-style-1">
                Welcome to Premier Chef Catering, a modern restaurant with a
                focus on premium food tastes
              </div>
              <p>
                We specialize in catering, distribution, and service provision
                for hotels, international schools, colleges, universities, and a
                variety of other organizations, drawing upon our extensive years
                of industry experience.
              </p>
              <a
                href="/"
                // href="/about"
                className="view-more margin-t36"
              >
                Read More
              </a>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid img-feature"
                src="images/home/about-7.jpg"
                loading="lazy"
                alt="about-7"
              />
            </div>
          </div>
        </div>
      </section>
      {/* /SECTION 2 */}

      {/* SECTION 3 */}
      {/* Add parallax class to a section and a background image to create the parallax effect */}
      <section
        className="home-widget parallax"
        style={{ backgroundImage: 'url("images/home/about-4.jpg")' }}
      >
        <div className="parallax-content">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="img-center alignc">
                  <img
                    className="img-fluid img-feature"
                    src="images/home/taste-food.png"
                    loading="lazy"
                    alt="taste-food"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /SECTION 3 */}

      {/* SECTION 4 */}
      <section id="home-content-4 " className="home-widget">
        <div className="container ">
          <div className="row">
            <div className="col-md-12 alignc ">
              <ul className="row our-menu">
                <li className="col-lg-6 col-md-12">
                  <h4 className="menu-title-section">HOT COFFEES</h4>
                  <div id="starters" className="menu-section">
                    <div className="menu-holder menu-1col menu-accordion">
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width={400}
                            height={400}
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f12bc8af498d45ed92c5d6f1dac64062_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>

                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Americano</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 45</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Rich, bold, espresso-based hot coffee.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/ec519dd5642c41629194192cce582135_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Espresso</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 50</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Strong, rich, concentrated, intense, energizing,
                            aromatic.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/5c515339667943ce84dc56effdf5fc1b_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Capuccino</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 50</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Rich, frothy, warm, invigorating, creamy,
                            delightful.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/2a7651bc593044ba9043e90ddc20e3f1_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Latte</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 50</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Rich, creamy, warm, comforting, smooth, aromatic.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f4ec500b34624242b15c2d29e53f3c48_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Mocha</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 50</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Rich, chocolatey, warm, aromatic, comforting,
                            delicious.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/58db701349cb48738069e8c912e2b3ac_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <sp className="menu-title">Caramel Macchiato</sp>
                            <span className="menu-dots"></span>an
                            <span className="menu-price">฿ 50</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Sweet, rich, creamy, espresso, vanilla, warm.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="col-lg-6 col-md-12">
                  <h4 className="menu-title-section">ICE COFFEES</h4>
                  <div id="main-courses" className="menu-section">
                    <div className="menu-holder  menu-accordion">
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/ec519dd5642c41629194192cce582135_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Americano</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 50</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Bold, refreshing, cold, smooth, invigorating.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info2 animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/ec519dd5642c41629194192cce582135_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Espresso</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 55</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Rich, bold, refreshing, cold, invigorating.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info2 animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/5c515339667943ce84dc56effdf5fc1b_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Capuccino</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 55</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Creamy, frothy, chilled, espresso, refreshing.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info2 animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/2a7651bc593044ba9043e90ddc20e3f1_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Latte</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 55</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Rich, creamy, chilled coffee delight.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info2 animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f4ec500b34624242b15c2d29e53f3c48_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Mocha</span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 55</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Rich, chocolatey, smooth, caffeinated, refreshing.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info2 animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width="400"
                            height="400"
                            src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/58db701349cb48738069e8c912e2b3ac_result-150x150.jpg"
                            alt="Tomato Bruschetta"
                            title="Tomato Bruschetta"
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">
                              Caramel Macchiato
                            </span>
                            <span className="menu-dots"></span>
                            <span className="menu-price">฿ 55</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Sweet, creamy, espresso-infused iced coffee.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info2 animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="col-lg-6 col-md-12">
                  <h4 className="menu-title-section">FRAPPE</h4>
                  <div id="soups" className="menu-section">
                    <div className="menu-holder menu-1col menu-accordion">
                      <div className="menu-post clearfix">
                        <div className="menu-post-img">
                          <img
                            width={400}
                            height={400}
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8Lxi9Ex0MoUjN9xaoMtdbkwkU9zW2HlTkNg&s"
                            className
                            alt=""
                            title
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Capuccino</span>{" "}
                            <span className="menu-dots" />
                            <span className="menu-price">฿ 60</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Creamy, frothy, coffee-flavored, chilled, sweet.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix  last ">
                        <div className="menu-post-img">
                          <img
                            width={400}
                            height={400}
                            src="https://media.istockphoto.com/id/1400793713/photo/cafe-mocha-with-whip-cream.jpg?s=612x612&w=0&k=20&c=31IKA85NJUYdjkJzM-1aNner-FXYGpB2Rc0QIJ4eWSA="
                            className
                            alt=""
                            title
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Latte</span>{" "}
                            <span className="menu-dots" />
                            <span className="menu-price">฿ 60</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Coffee, milk, ice, blended, sweet.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix ">
                        <div className="menu-post-img">
                          <img
                            width={400}
                            height={400}
                            src="https://images.pexels.com/photos/11512983/pexels-photo-11512983.jpeg?auto=compress&cs=tinysrgb&w=600"
                            className
                            alt=""
                            title
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">Mocha</span>{" "}
                            <span className="menu-dots" />
                            <span className="menu-price">฿ 60</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Mocha FRAPPE: rich, creamy, espresso delight.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix last">
                        <div className="menu-post-img">
                          <img
                            width={400}
                            height={400}
                            src="https://cooktoria.com/wp-content/uploads/2018/03/Caramel-Frappuccino-Starbucks-2.jpg"
                            className
                            alt=""
                            title
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">
                              Caramel Macchiato
                            </span>{" "}
                            <span className="menu-dots" />
                            <span className="menu-price">฿ 60</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Sweet, creamy, iced coffee delight.{" "}
                          </div>
                        </div>
                        <div
                          ref={elementRef}
                          className={`nutritional-info animated ${
                            showAnimation ? "animatedFadeInUp fadeInUp" : ""
                          }`}
                        >
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                {/* <li className="col-lg-6 col-md-12">
                  <h4 className="menu-title-section">Desserts</h4>
                  <div id="desserts" className="menu-section">
                    <div className="menu-holder menu-1col menu-accordion">
                      <div className="menu-post clearfix ">
                        <div className="menu-post-img">
                          <img
                            width={400}
                            height={400}
                            src="images/menu/summer-berry.jpg"
                            className
                            alt
                            title
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">
                              Summer Berry and Coconut Tart
                            </span>{" "}
                            <span className="menu-dots" />
                            <span className="menu-price">฿ $12.00</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Raspberries, blackberries, blueberries, graham
                            cracker crumbs{" "}
                          </div>
                        </div>
                        <div ref={elementRef} className={`nutritional-info2 animated ${showAnimation ? 'animatedFadeInUp fadeInUp' : ''}`}>
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix  last ">
                        <div className="menu-post-img">
                          <img
                            width={400}
                            height={400}
                            src="images/menu/pumpkin-cookies.jpg"
                            className
                            alt
                            title
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">
                              Pumpkin Cookies Cream Cheese
                            </span>{" "}
                            <span className="menu-dots" />
                            <span className="menu-price">฿ $10.00</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Pumpkin, sugar, butter, eggs{" "}
                          </div>
                        </div>
                        <div ref={elementRef} className={`nutritional-info2 animated ${showAnimation ? 'animatedFadeInUp fadeInUp' : ''}`}>
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                      <div className="menu-post clearfix ">
                        <div className="menu-post-img">
                          <img
                            width={400}
                            height={400}
                            src="images/menu/chocolate-cupcakes.jpg"
                            className
                            alt
                            title
                          />
                        </div>
                        <div className="menu-post-desc">
                          <h4>
                            <span className="menu-title">
                              Double Chocolate Cupcakes
                            </span>{" "}
                            <span className="menu-dots" />
                            <span className="menu-price">฿ $7.00</span>
                          </h4>
                          <div className="menu-text">
                            {" "}
                            Chocolate, eggs, vanilla, milk{" "}
                          </div>
                        </div>
                        <div ref={elementRef} className={`nutritional-info2 animated ${showAnimation ? 'animatedFadeInUp fadeInUp' : ''}`}>
                          <h6>NUTRITION INFORMATION</h6>
                          <ul className="custom-list">
                            <li className="p-0 m-0">
                              <strong>Calories</strong> 480
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Fat</strong> 20g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Cholesterol</strong> 60mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Sodium</strong> 220 mg
                            </li>
                            <li className="p-0 m-0">
                              <strong>Total Carbohydrates</strong> 71g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Protein</strong> 5g
                            </li>
                            <li className="p-0 m-0">
                              <strong>Caffeine</strong> 80 mg
                            </li>
                          </ul>
                          <p>
                            * 2,000 calories a day is used for general nutrition
                            advice, but calorie needs vary.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* /SECTION 4 */}

      {/* SECTION 5 */}
      {/* Add parallax class to a section and a background image to create the parallax effect */}
      <section
        className="home-widget parallax"
        style={{ backgroundImage: 'url("images/home/about-5.jpg")' }}
      >
        <div className="parallax-content">
          <div className="container">
            <div className="row">
              <div className="col-md-12 alignc">
                <h2 className="home-subtitle">Reserve a Table</h2>
                <h1 className="home-title title-headline margin-b24">
                  Open Hours
                </h1>
                <p>
                  Weekdays and weekends, our cafe operates from 8:00 AM to 4:00
                  PM.{" "}
                </p>
                {/* <p>Saturday - Sunday: 9 AM - 1 PM </p> */}
                <a className="view-more more-white margin-t36" href="#">
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /SECTION 5 */}

      {/* SECTION 6 */}
      {/* <section id="home-wine" className="home-widget">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <img
                    className="img-fluid"
                    src="images/home/about-8.jpg"
                    loading="lazy"
                    alt="about-6"
                  />
                </div>
                <div className="col-md-6 padding-tb54 alignr">
                  <h2 className="home-subtitle">Experience</h2>
                  <h1 className="home-title title-headline margin-b24">Wine</h1>
                  <div className="title-style-1">
                    A unique dinner works best with wine pairing
                  </div>
                  <p>
                    You can choose from a variety of styles, light and easy
                    drinking, fresh and aromatic white wines or you could try
                    intense and elegant red wines. Creative cuisine involves
                    sophisticated wines.{" "}
                  </p>
                  <p>
                    Vestibulum eleifend gravida neque a bibendum. Vivamus
                    viverra velit non cursus elementum. Donec sit amet posuere
                    ipsum. Mauris rutrum sagittis sapien text link. In vitae
                    ipsum eleifend, auctor turpis in, vestibulum dui. Ut
                    vestibulum, lorem id eleifend mollis, urna augue imperdiet
                    ante, vitae aliquam turpis mauris eget nisi. Sed vel purus
                    id velit molestie.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* /SECTION 6 */}

      {/* SECTION 7 */}
      {/* Add parallax class to a section and a background image to create the parallax effect */}
      <section
        className="home-widget parallax"
        style={{ backgroundImage: 'url("images/home/about-9.jpg")' }}
      >
        <div className="parallax-content">
          <div className="container">
            <div className="row">
              <div className="col-md-12 alignc">
                <h2 className="home-subtitle">Visit Us</h2>
                <h1 className="home-title title-headline margin-b24">
                  Good Food and Drinks
                </h1>
                <p>
                  Premier Chef Catering Co.,Ltd. 9/1 Soi Ramkhamhaeng 118 Yeak
                  46-4 Sapansung, Bangkok 10240{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Home;
