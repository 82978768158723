import { fetchAPI } from "../../store/apiConfig";

//Get Pending Reservations Data
export const GetReservationList = async () => {
  return fetchAPI(`/list-reservations`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

//Get Pending Reservations Data
export const GetPendingReservationList = async () => {
  return fetchAPI(`/list-reservations?status=Pending`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

//Get Confirmed Reservations Data
export const GetConfirmedReservationList = async () => {
  return fetchAPI(`/list-reservations?status=Confirmed`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

//Update Reservation (date, time or Reservation Approve-Reject)
export const UpdateReservation = async (
  id,
  status,
  newDate,
  newTimeFrom,
  newTimeTo
) => {
  return fetchAPI(`/edit-reservations?ID=${id}`, {
    method: "POST",
    body: JSON.stringify({
      ...(status !== undefined && { status }),
      ...(newDate && { reservationdate: newDate }),
      ...(newTimeFrom &&
        newTimeTo && { time: { from: newTimeFrom, to: newTimeTo } }),
    }),
  });
};

//Create Reservation
export const createReservation = async (payload) => {
  return fetchAPI(`/reservations`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};
