import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { get } from "lodash";
import {
  GetReservationList,
  GetPendingReservationList,
  GetConfirmedReservationList,
  UpdateReservation,
  createReservation,
} from "../../components/API/reservationsListAPI";
// import { UpdateReservation } from "../../components/API/reservationsListAPI";

const initialState = {
  reservations: [],
  pendingReservations: [],
  confirmedReservations: [],
  items: [],
  loading: false,
  error: null,
};

export const getReservationList = createAsyncThunk(
  "reservations/getReservationList",
  async (_, { rejectWithValue }) => {
    try {
      const data = await GetReservationList();
      // console.log("ReservationList:", data.data);
      return data.data;
    } catch (error) {
      console.error("API Error:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const getPendingReservationList = createAsyncThunk(
  "reservations/getPendingReservationList",
  async (_, { rejectWithValue }) => {
    try {
      const data = await GetPendingReservationList();
      // console.log("PendingReservationList:", data.data);
      return data.data;
    } catch (error) {
      console.error("API Error:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const getConfirmedReservationList = createAsyncThunk(
  "reservations/getConfirmedReservationList",
  async (_, { rejectWithValue }) => {
    try {
      const data = await GetConfirmedReservationList();
      // console.log("ConfirmedReservationList:", data.data);
      return data.data;
    } catch (error) {
      console.error("API Error:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateReservation = createAsyncThunk(
  "reservations/updateReservation",
  async (
    { id, status, newDate, newTimeFrom, newTimeTo },
    { rejectWithValue }
  ) => {
    try {
      const data = await UpdateReservation(
        id,
        status,
        newDate,
        newTimeFrom,
        newTimeTo
      );
      // console.log("Update data :>> ", data);
      return data;
    } catch (error) {
      console.error("API Error on update:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const addReservation = createAsyncThunk(
  "reservations/addReservation",
  async (reservationData, { rejectWithValue }) => {
    try {
      const response = await createReservation(reservationData);
      // console.log('response :>> ', response);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const reservationsSlice = createSlice({
  name: "reservations",
  initialState,
  reducers: {
    updateReservationState: (state, action) => {
      const index = state.reservations.findIndex(
        (res) => res.id === action.payload.id
      );
      if (index !== -1) {
        state.reservations[index] = {
          ...state.reservations[index],
          ...action.payload.reservationData,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReservationList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReservationList.fulfilled, (state, action) => {
        state.reservations = action.payload;
        state.loading = false;
      })
      .addCase(getReservationList.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(getPendingReservationList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPendingReservationList.fulfilled, (state, action) => {
        state.pendingReservations = action.payload;
        state.loading = false;
      })
      .addCase(getPendingReservationList.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(getConfirmedReservationList.pending, (state, action) => {
        if (state.confirmedReservations.length === 0) {
          state.loading = false;
        } else {
          state.loading = true;
        }
      })
      .addCase(getConfirmedReservationList.fulfilled, (state, action) => {
        state.confirmedReservations = action.payload;
        state.loading = false;
      })
      .addCase(getConfirmedReservationList.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(updateReservation.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateReservation.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.reservations = Array.isArray(payload) ? payload : [];
      })
      .addCase(updateReservation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(addReservation.pending, (state) => {
        state.loading = true;
      })
      .addCase(addReservation.fulfilled, (state, action) => {
        state.items.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(addReservation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { reducer } = reservationsSlice;

export default reservationsSlice.reducer;
