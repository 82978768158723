import React from 'react'
import "../styles/style.css"
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import EditIcon from "@mui/icons-material/Edit";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import MessageRoundedIcon from "@mui/icons-material/MessageRounded";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLine } from '@fortawesome/free-brands-svg-icons';


const SpeedDail = () => {

    const actions = [
        { icon: <WhatsAppIcon />, name: "Whatsapp" },
        { icon: <MailIcon />, name: "Mail" },
        { icon: <CallIcon />, name: "Call" },
      ];

      const whatsappNumber = "+88 0613876868";
const whatsappLink = `https://wa.me/${whatsappNumber}`;
    
  return (
    <>
     <Box
          sx={{
            transform: "translateZ(0px)",
            flexGrow: 1,
            position: "fixed",
            bottom: 20,
            left: 20,
          }}
        >
          <SpeedDial
            ariaLabel="SpeedDial openIcon example"
            // sx={{ position: "absolute", bottom: 1, left: 20 }}
            icon={<MessageRoundedIcon icon={<EditIcon />} sx={{color:"#bc8d4b"}} />}
            // icon={<MessageRoundedIcon openIcon={<EditIcon />} sx={{color:"gold"}} />}
            sx={{
              // The following targets the fab button directly for more specific styling
              '& .MuiFab-root': {
                bgcolor: '#393838', // Example of changing the fab part's color directly
                '&:hover': {
                  bgcolor: '#4d4c4c', // Example of changing the fab part's hover color
                },
              },
            }}
          >
            {/* {actions.map((action) => ( */}
              <SpeedDialAction
                sx={{color:"#bc8d4b"}}
                icon={<a href="tel:+880613876868" style={{ color: 'inherit', textDecoration: 'none' }}>
                <CallIcon />
              </a>}
                tooltipTitle="call"
              />
              <SpeedDialAction
                sx={{color:"#bc8d4b"}}
                icon={<FontAwesomeIcon icon={faLine} />}
                tooltipTitle="line"
              />
              <SpeedDialAction
                sx={{color:"#bc8d4b"}}
                icon={ <a href="https://wa.me/880613876868" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
                <WhatsAppIcon />
              </a>}
                tooltipTitle="Whatsapp"
              />
            {/* ))} */}
          </SpeedDial>
        </Box>
    </>
  )
}

export default SpeedDail
