import { combineReducers } from "@reduxjs/toolkit";
import reservationsSlice from "./slice/reservationsSlice";

const combinedReducer = combineReducers({
  reservation: reservationsSlice,
});

export const rootReducer = (state, action) => {
  if (action.type === "auth/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};
