import React, { useEffect, useRef, useState } from "react";
import "../styles/style.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { imageGallery } from "./GalleryImages";
import SpeedDail from "./SpeedDail";
import GoogleTranslate from "./Google-translate";
import Footer from "./Footer";

const Gallery = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const handleSubMenuClick = (index) => (e) => {
    e.preventDefault();
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  // Function to handle scroll event
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => setIsMenuOpen(!isMenuOpen);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const [showAnimation, setShowAnimation] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const checkPosition = () => {
      if (!elementRef.current) {
        // Element is not yet available in the DOM, exit early
        return;
      }

      const element = elementRef.current;
      const elementPosition = element.getBoundingClientRect().top;
      const viewportHeight = window.innerHeight;

      // Example: Trigger animation when element is in the viewport
      if (elementPosition < viewportHeight) {
        setShowAnimation(true);
      } else {
        setShowAnimation(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", checkPosition);

    // Check position initially in case the element is already in view on initial load
    checkPosition();

    // Clean up
    return () => window.removeEventListener("scroll", checkPosition);
  }, []);

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageLoadError, setImageLoadError] = useState(false);

  const openLightbox = (images) => {
    setCurrentImageIndex(images);
    setIsLightboxOpen(true);
    setImageLoadError(false);
  };

  const handleImageError = () => {
    setImageLoadError(true);
  };

  // const [photoIndex, setPhotoIndex] = useState(0);
  // const [isOpen, setIsOpen] = useState(false);

  const [isVisible, setIsVisible] = useState(false);

  // Function to check the scroll position
  const checkScrollTop = () => {
    if (!isVisible && window.pageYOffset > 400) {
      setIsVisible(true);
    } else if (isVisible && window.pageYOffset <= 400) {
      setIsVisible(false);
    }
  };

  // Function to scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  });

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  // Deduce the unique categories
  const categories = [
    "All",
    ...new Set(imageGallery.map((img) => img.category)),
  ];

  const filteredImages = imageGallery.filter(
    (img) => selectedCategory === "All" || img.category === selectedCategory
  );

  return (
    <div>
      <div>
        {/* MOBILE MENU */}
        {isActive && (
          <div className="mask-nav-2 is-active">
            {/* MENU */}
            <nav className="navbar navbar-2 nav-mobile">
              <div className="nav-holder nav-holder-2">
                <ul id="menu-menu-2" className="menu-nav-2">
                  <li
                    className={`menu-item  ${
                      activeSubMenu === 1 ? "menu-open" : ""
                    }`}
                  >
                    <a href="/">Home</a>
                  </li>
                  <li className="menu-item">
                    <a href="/menu">Menu</a>
                  </li>

                  <li className="menu-item">
                    <a href="/reservation">Reservation</a>
                  </li>
                  <li className="menu-item ">
                    <a href="/gallery">Gallery</a>
                  </li>

                  <li className="menu-item ">
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
            </nav>
            {/* /MENU */}
          </div>
        )}
        {/* /MOBILE MENU */}
        {/* HEADER */}
        <header id="header-1">
          <div className="headerWrap-1">
            <nav className="navbar-1">
              {/* TOP LEFT PAGE TEXT  */}
              <div className="top-location">
                <span className="info-txt">
                  <a href="/" style={{ position: "relative", bottom: "0" }}>
                    <img
                      // className="img-fluid"
                      src="images/Premier-chef-logo.png"
                      alt="logo"
                      style={{ height: "100px" }}
                    />
                  </a>{" "}
                </span>

                {/* <span className="info-txt">New York 70250</span>    */}
              </div>

              {/* MOBILE BUTTON NAV  */}
              <div
                className={`nav-button-holder nav-btn-mobile inactive ${
                  isActive ? "active" : "inactive"
                }`}
                onClick={toggleMenu}
                style={{ marginTop: "5px" }}
              >
                {/* <span className="menu-txt" style={{ fontSize: "18px" }}>
                  MENU
                </span> */}
                <button className="nav-button">
                  <span
                    className="fa fa-bars"
                    style={{ color: "white", fontSize: "30px" }}
                  />
                </button>
              </div>
              <div className="nav-content-1">
                {/* LOGO */}
                <div className="logo-1">
                  <a href="/" className="navbar-brand p-0">
                    <img
                      className="img-fluid"
                      src="images/Premier-chef-logo.png"
                      alt="logo"
                      style={{ height: "80px" }}
                    />
                  </a>
                </div>
                {/* MENU */}
                <div className="nav-holder nav-holder-1 nav-holder-desktop">
                  <ul
                    id="menu-menu-1"
                    className="menu-nav menu-nav-1"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <li className="menu-item ">
                      <a href="/">Home</a>
                    </li>
                    <li className="menu-item ">
                      <a href="/menu" onMouseEnter={handleMouseEnter}>
                        Menu
                      </a>
                    </li>
                    {/* menu-item-has-children  */}
                    <li className="menu-item">
                      <a href="/reservation">Reservation</a>
                    </li>
                    <li className="menu-item current-menu-item">
                      <a href="/gallery">Gallery</a>
                    </li>

                    <li className="menu-item ">
                      <a href="/contact">Contact</a>
                    </li>
                  </ul>
                </div>
                {/* /MENU */}
              </div>
            </nav>
          </div>
          {/*headerWrap*/}
        </header>
        {/* /HEADER */}
      </div>

      {/* {isHovered && (
        <div
          ref={elementRef}
          className={`menu animated ${
            showAnimation ? "animatedFadeInUp fadeInUp" : ""
          }`}
          onMouseLeave={handleMouseLeave}
        >
          <nav className={`menu-content ${isMenuOpen ? "active" : ""}`}>
            <div className="menu-holder menu-1col header-menu-accordion">
              <h4>
                <span className="header-menu-title">HOT COFFEES</span>
              </h4>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width={400}
                    height={400}
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f12bc8af498d45ed92c5d6f1dac64062_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>

                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFE AMERICANO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Espresso shots topped with hot water{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/5c515339667943ce84dc56effdf5fc1b_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAPPUCCINO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Dark espresso lies in wait under milk foam{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/58db701349cb48738069e8c912e2b3ac_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CARAMEL MACCHIATO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Freshly steamed milk with vanilla-flavored syrup{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f4ec500b34624242b15c2d29e53f3c48_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFFE MOCHA</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$7.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Full-bodied espresso combined with mocha{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-holder menu-1col header-menu-accordion">
              <h4>
                <span className="header-menu-title">COLD COFFEES</span>
              </h4>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width={400}
                    height={400}
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f12bc8af498d45ed92c5d6f1dac64062_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>

                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFE AMERICANO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Espresso shots topped with hot water{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/5c515339667943ce84dc56effdf5fc1b_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAPPUCCINO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Dark espresso lies in wait under milk foam{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/58db701349cb48738069e8c912e2b3ac_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CARAMEL MACCHIATO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Freshly steamed milk with vanilla-flavored syrup{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f4ec500b34624242b15c2d29e53f3c48_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFFE MOCHA</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$7.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Full-bodied espresso combined with mocha{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-holder menu-1col header-menu-accordion">
              <h4>
                <span className="header-menu-title">BAKERY</span>
              </h4>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width={400}
                    height={400}
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f12bc8af498d45ed92c5d6f1dac64062_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>

                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFE AMERICANO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Espresso shots topped with hot water{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/5c515339667943ce84dc56effdf5fc1b_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAPPUCCINO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Dark espresso lies in wait under milk foam{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/58db701349cb48738069e8c912e2b3ac_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CARAMEL MACCHIATO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Freshly steamed milk with vanilla-flavored syrup{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f4ec500b34624242b15c2d29e53f3c48_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFFE MOCHA</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$7.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Full-bodied espresso combined with mocha{" "}
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      )} */}

      <div>
        {/* TOP IMAGE HEADER */}
        <section className="topSingleBkg topPageBkg">
          <div className="item-content-bkg">
            <div
              className="item-img"
              style={{
                backgroundImage: 'url("images/top-headers/top-img-5.jpg")',
              }}
            />
            <div className="inner-desc">
              <h1 className="post-title single-post-title">Gallery</h1>
              <span className="post-subtitle">
                {" "}
                Premier Chef Delicious Food Portfolio, No.1 Catering of Bangkok
              </span>
            </div>
          </div>
        </section>

        {/* MAIN WRAP CONTENT */}
        <section id="wrap-content" className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {/* Category Selector */}
                <div className="category-selector">
                  {categories.map((category) => (
                    <button
                      key={category}
                      onClick={() => setSelectedCategory(category)}
                      style={{
                        backgroundColor:
                          selectedCategory === category
                            ? "#cc9649"
                            : "#eeeeeebf",
                        color: selectedCategory === category ? "white" : "",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        padding: "10px 20px",
                      }}
                    >
                      {category}
                    </button>
                  ))}
                </div>

                {/* GALLERY */}
                <div className="gallery-4colgrid-content">
                  <div className="menu-holder menu-3col-grid-image gallery-holder gallery-4col clearfix">
                    {filteredImages.map((imgs, index) => (
                      <div className="menu-post gallery-post">
                        <a
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent default anchor action
                            setPhotoIndex(index);
                            setIsOpen(true);
                          }}
                          className="lightbox"
                          rel="prettyPhoto[gallery]"
                          title={imgs.title}
                        >
                          <div className="item-content-bkg gallery-bkg">
                            <div
                              className="gallery-img"
                              style={{
                                backgroundImage: `url(${imgs.src})`,
                              }}
                            />
                            {/* <div className="menu-post-desc">
                              <h4>{imgs.title}</h4>
                              <div className="gallery-mglass">
                                <i className="fas fa-search" />
                              </div>
                            </div> */}
                          </div>
                        </a>
                      </div>
                    ))}

                    {isOpen && (
                      <Lightbox
                        mainSrc={imageGallery[photoIndex].src}
                        nextSrc={
                          imageGallery[(photoIndex + 1) % imageGallery.length]
                            .src
                        }
                        prevSrc={
                          imageGallery[
                            (photoIndex + imageGallery.length - 1) %
                              imageGallery.length
                          ].src
                        }
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                          setPhotoIndex(
                            (photoIndex + imageGallery.length - 1) %
                              imageGallery.length
                          )
                        }
                        onMoveNextRequest={() =>
                          setPhotoIndex((photoIndex + 1) % imageGallery.length)
                        }
                        imageTitle={imageGallery[photoIndex].title}
                      />
                    )}
                  </div>
                </div>
                {/* /GALLERY */}
              </div>
              {/*col-md-12*/}
            </div>
            {/*row*/}
          </div>
          {/*container*/}
        </section>
        {/* /MAIN WRAP CONTENT */}
      </div>

      <Footer />
    </div>
  );
};

export default Gallery;
