import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation 
} from "react-router-dom";
import Home from './components/Home.jsx';
import Menu from './components/Menu.jsx';
import Contact from './components/Contact.jsx';
import Gallery from './components/Gallery.jsx';
import Reservation from './components/Reservation.jsx';
import Footer from './components/Footer.jsx';
import Login from './components/Login.jsx';
import ListOfReservation from './components/ListOfReservation.jsx';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './auth/ProtectedRoute.jsx';
import About from './components/About.jsx';
import SidebarMenu from './components/admin/SidebarMenu.jsx.jsx';

function App() {
  return (
    <Router>
      <RoutesWithFooter />
    </Router>
  );
}

function RoutesWithFooter() {
  const location = useLocation();

  return (
    <>
    <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/about" element={<About/>} />
        <Route 
          path="/admin" 
          element={
            <ProtectedRoute role="admin">
              <SidebarMenu />
            </ProtectedRoute>
          } 
        />
      </Routes>
      
      {/* Check the pathname and conditionally render the Footer */}
      {/* {location.pathname !== '/admin' && <Footer />} */}
    </>
  );
}

export default App;
