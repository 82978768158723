import React, { useEffect, useMemo, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/style.css";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { Visibility as VisibilityIcon } from "@material-ui/icons";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import useWindowSize from "../../hooks/useWindowSize";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { getConfirmedReservationList } from "../../store/slice/reservationsSlice";

const Reserved = () => {
  const { width } = useWindowSize();
  const isMobile = width < 768;

  const [selectedDate, setSelectedDate] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const dispatch = useDispatch();

  const { confirmedReservations, loading } = useSelector(
    (state) =>
      state.reservation || { confirmedReservations: [], loading: false }
  );

  useEffect(() => {
    if (!loading && confirmedReservations.length === 0) {
      dispatch(getConfirmedReservationList());
    }

    // Polling logic
    const intervalId = setInterval(() => {
      dispatch(getConfirmedReservationList());
    }, 3000); 

    return () => clearInterval(intervalId);
  }, [dispatch, loading, confirmedReservations.length]);

  const filteredAndSortedData = useMemo(() => {
    if (!confirmedReservations || !confirmedReservations) return [];

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to midnight for comparison

    const start = startDate ? new Date(startDate).setHours(0, 0, 0, 0) : null;
    const end = endDate ? new Date(endDate).setHours(23, 59, 59, 999) : null;

    const filteredData = confirmedReservations.filter((reservation) => {
      const reservationDate = new Date(reservation.reservationdate);
      reservationDate.setUTCHours(0, 0, 0, 0);

      return (
        (!start || reservationDate >= start) && (!end || reservationDate <= end)
      );
    });

    return filteredData.sort(
      (a, b) => new Date(a.reservationdate) - new Date(b.reservationdate)
    );
  }, [confirmedReservations, startDate, endDate]);

  const columns = [
    {
      name: "Student ID",
      selector: (row) => row.studentid,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => formatDate(row.reservationdate),
      sortable: true,
    },
    {
      name: "Time",
      selector: (row) =>
        `${formatTime24To12(row.time.from)} - ${formatTime24To12(row.time.to)}`,
      sortable: true,
    },
    {
      name: "Occasion",
      selector: (row) => row.occasion,
      sortable: true,
    },
    {
      name: "Approved",
      cell: (row) => (
        <IconButton>
          <CheckBoxIcon style={{ color: "green" }} />
        </IconButton>
      ),
      ignoreRowClick: false,
      allowOverflow: false,
      button: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <IconButton onClick={() => handleView(row._id)}>
          <VisibilityIcon />
        </IconButton>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const columnsToShow =
    width < 768
      ? columns.filter((column) =>
          ["Student ID", "Date", "Action"].includes(column.name)
        )
      : columns;

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgba(33, 37, 41, 0.19)",
        borderBottomColor: "#FFFFFF",
        borderRadius: "25px",
        outline: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  const handleView = (id) => {
    // console.log("Viewing details for ID:", id);

    const reservation = confirmedReservations.find(
      (reservation) => reservation._id === id
    );
    setSelectedReservation(reservation);
    setIsDrawerOpen(true);
  };

  // useEffect(() => {
  //   if (localStorage.getItem("loginSuccess") === "true") {
  //     toast.success("Login successful!", {
  //       position: "top-right",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     localStorage.removeItem("loginSuccess");
  //   }
  // }, []);

  // Helper function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // JavaScript months are 0-based.
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Helper function to format time
  const formatTime24To12 = (time24) => {
    if (!time24) return ""; // handle undefined or null inputs
    const [hour, minute] = time24.split(":");
    const hourInt = parseInt(hour, 10);
    const ampm = hourInt >= 12 ? "PM" : "AM";
    const hour12 = hourInt % 12 === 0 ? 12 : hourInt % 12;
    return `${hour12}:${minute} ${ampm}`;
  };

  const processData = () => {
    const filteredData = confirmedReservations.filter((reservation) => {
      if (!startDate || !endDate) return true;
      const reservationDate = new Date(reservation.reservationdate);
      return (
        !startDate ||
        !endDate ||
        (reservationDate >= startDate && reservationDate <= endDate)
      );
    });

    return filteredData.sort((a, b) => {
      const dateA = new Date(a.reservationdate);
      const dateB = new Date(b.reservationdate);
      return dateA - dateB; // Sorting in ascending order
    });
  };

  const processedData = useMemo(() => {
    return processData();
  }, [confirmedReservations, startDate, endDate]);

  const handleResetFilter = () => {
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    handleResetFilter();
  }, []);

  const NoDataComponent = () => (
    <div style={{ padding: "20px", textAlign: "center" }}>
      No Any Approved Reservations
    </div>
  );

  return (
    <div className="p-0 p-md-3 mt-5" id="Reserved-list">
      <div className="date-picker d-flex justify-content-end mx-3">
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          isClearable
          placeholderText="Start Date"
        />
        &nbsp; &nbsp;
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          isClearable
          placeholderText="End Date"
        />
      </div>

      <DataTable
        title="Approved List"
        columns={columnsToShow}
        data={filteredAndSortedData} // Updated to use processed data
        pagination
        noDataComponent={<NoDataComponent />}
        customStyles={customStyles}
        highlightOnHover
        pointerOnHover
      />

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        PaperProps={{
          style: {
            width: isMobile ? "80%" : "30%",
          },
        }}
      >
        <Typography variant="h5" component="h2" className="p-3">
          Details
        </Typography>
        <hr className="text-secondary m-0" style={{ width: "100%" }} />
        <List>
          {/* <hr className="text-secondary" style={{ width: "100%" }} /> */}
          {selectedReservation?.studentid && (
            <ListItem sx={{pb: 0}}>
              <ListItemText
                // className="d-flex flex-row"
                primary="Student ID :"
                secondary={selectedReservation?.studentid}
              />
            </ListItem>
          )}

          {selectedReservation?.studentname && (
            <ListItem sx={{pb: 0}}>
              <ListItemText
                primary="Student Name :"
                secondary={selectedReservation?.studentname}
              />
            </ListItem>
          )}

          {selectedReservation?.studentemail && (
            <ListItem sx={{pb: 0}}>
              <ListItemText
                primary="Student Email :"
                secondary={selectedReservation?.studentemail}
              />
            </ListItem>
          )}

          {selectedReservation?.studentclass && (
            <ListItem sx={{pb: 0}}>
              <ListItemText
                primary="Class :"
                secondary={selectedReservation?.studentclass}
              />
            </ListItem>
          )}

          {selectedReservation?.mobile && (
            <ListItem sx={{pb: 0}}>
              <ListItemText
                primary="Mobile No :"
                secondary={selectedReservation?.mobile}
              />
            </ListItem>
          )}

          <ListItem sx={{pb: 0}}>
            <ListItemText
              primary="Date :"
              secondary={
                selectedReservation
                  ? formatDate(selectedReservation.reservationdate)
                  : "No date"
              }
            />
          </ListItem>

          <ListItem sx={{pb: 0}}>
            <ListItemText
              primary="Time :"
              secondary={`${formatTime24To12(
                selectedReservation?.time?.from
              )} - ${formatTime24To12(selectedReservation?.time?.to)}`}
            />
          </ListItem>

          {selectedReservation?.occasion && (
            <ListItem sx={{pb: 0}}>
              <ListItemText
                primary="Occasion :"
                secondary={selectedReservation?.occasion}
              />
            </ListItem>
          )}

          {selectedReservation?.numberOfPeople && (
            <ListItem sx={{pb: 0}}>
              <ListItemText
                primary="Number Of People :"
                secondary={selectedReservation?.numberOfPeople}
              />
            </ListItem>
          )}

          {selectedReservation?.note && (
            <ListItem sx={{pb: 0}}>
              <ListItemText
                primary="Note :"
                secondary={selectedReservation?.note}
              />
            </ListItem>
          )}
        </List>
      </Drawer>
    </div>
  );
};

export default Reserved;
