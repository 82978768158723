import I1 from "./GalleryImages/1.jpg";
import I2 from "./GalleryImages/2.jpg";
import I3 from "./GalleryImages/3.jpg";
import I4 from "./GalleryImages/4.jpg";
import I5 from "./GalleryImages/5.jpg";
import I6 from "./GalleryImages/6.jpg";
import I7 from "./GalleryImages/7.jpg";
import I8 from "./GalleryImages/8.jpg";
import I9 from "./GalleryImages/9.jpg";
import I10 from "./GalleryImages/10.jpg";
import I11 from "./GalleryImages/11.jpg";
import I12 from "./GalleryImages/12.jpg";
import I13 from "./GalleryImages/13.jpg";
import I14 from "./GalleryImages/14.jpg";
import I15 from "./GalleryImages/15.jpg";
import I16 from "./GalleryImages/16.jpg";
import I17 from "./GalleryImages/17.jpg";
import I18 from "./GalleryImages/18.jpg";
import I19 from "./GalleryImages/19.jpg";
import I20 from "./GalleryImages/20.jpg";
import I21 from "./GalleryImages/21.jpg";
import I22 from "./GalleryImages/22.jpg";
import I23 from "./GalleryImages/23.jpg";
import I24 from "./GalleryImages/24.jpg";
import I25 from "./GalleryImages/25.jpg";
import I26 from "./GalleryImages/26.jpg";
import I27 from "./GalleryImages/27.jpg";
import I28 from "./GalleryImages/28.jpg";
import I29 from "./GalleryImages/29.jpg";
import I30 from "./GalleryImages/30.jpg";
import I31 from "./GalleryImages/31.jpg";
import I32 from "./GalleryImages/32.jpg";
import I33 from "./GalleryImages/33.jpg";
import I34 from "./GalleryImages/34.jpg";
import I35 from "./GalleryImages/35.jpg";
import I36 from "./GalleryImages/36.jpg";
import I37 from "./GalleryImages/37.jpg";
import I38 from "./GalleryImages/38.jpg";
import I39 from "./GalleryImages/39.jpg";
import I40 from "./GalleryImages/40.jpg";
import I41 from "./GalleryImages/41.jpg";
import I42 from "./GalleryImages/42.jpg";
import I43 from "./GalleryImages/43.jpg";
import I44 from "./GalleryImages/44.jpg";
import I45 from "./GalleryImages/45.jpg";
import I46 from "./GalleryImages/46.jpg";
import I47 from "./GalleryImages/47.jpg";
import I48 from "./GalleryImages/48.jpg";
import I49 from "./GalleryImages/49.jpg";
import I50 from "./GalleryImages/50.jpg";
import I51 from "./GalleryImages/51.jpg";
import I52 from "./GalleryImages/52.jpg";
import I53 from "./GalleryImages/53.jpg";
import I54 from "./GalleryImages/54.jpg";
import I55 from "./GalleryImages/55.jpg";
import I56 from "./GalleryImages/56.jpg";
import I57 from "./GalleryImages/57.jpg";
import I58 from "./GalleryImages/58.jpg";
import I59 from "./GalleryImages/59.jpg";
import I60 from "./GalleryImages/60.jpg";
import I61 from "./GalleryImages/61.jpg";
import I62 from "./GalleryImages/62.jpg";
import I63 from "./GalleryImages/63.jpg";
import I64 from "./GalleryImages/64.jpg";
import I65 from "./GalleryImages/65.jpg";
import I66 from "./GalleryImages/66.jpg";
import I67 from "./GalleryImages/67.jpg";
import I68 from "./GalleryImages/68.jpg";
import I69 from "./GalleryImages/69.jpg";
import I70 from "./GalleryImages/70.jpg";
import I71 from "./GalleryImages/71.jpg";
import I72 from "./GalleryImages/72.jpg";
import I73 from "./GalleryImages/73.jpg";
import I74 from "./GalleryImages/74.jpg";
import I75 from "./GalleryImages/75.jpg";
import I76 from "./GalleryImages/76.jpg";
import I77 from "./GalleryImages/77.jpg";
import I78 from "./GalleryImages/78.jpg";
import I79 from "./GalleryImages/79.jpg";
import I80 from "./GalleryImages/80.jpg";
import I81 from "./GalleryImages/81.jpg";
import I82 from "./GalleryImages/82.jpg";
import I83 from "./GalleryImages/83.jpg";
import I84 from "./GalleryImages/84.jpg";
import I85 from "./GalleryImages/85.jpg";
import I86 from "./GalleryImages/86.jpg";
import I87 from "./GalleryImages/87.jpg";
import I88 from "./GalleryImages/88.jpg";
import I89 from "./GalleryImages/89.jpg";
import I90 from "./GalleryImages/90.jpg";
import I91 from "./GalleryImages/91.jpg";
import I92 from "./GalleryImages/92.jpg";
import I93 from "./GalleryImages/93.jpg";
import I94 from "./GalleryImages/94.jpg";
import I95 from "./GalleryImages/95.jpg";
import I96 from "./GalleryImages/96.jpg";
import I97 from "./GalleryImages/97.jpg";
import I98 from "./GalleryImages/98.jpg";
import I99 from "./GalleryImages/99.jpg";
import I100 from "./GalleryImages/100.jpg";
// Continues with the pattern
import I101 from "./GalleryImages/101.jpg";
import I102 from "./GalleryImages/102.jpg";
import I103 from "./GalleryImages/103.jpg";
import I104 from "./GalleryImages/104.jpg";
import I105 from "./GalleryImages/105.jpg";
import I106 from "./GalleryImages/106.jpg";
import I107 from "./GalleryImages/107.jpg";
import I108 from "./GalleryImages/108.jpg";
import I109 from "./GalleryImages/109.jpg";
import I110 from "./GalleryImages/110.jpg";
import I111 from "./GalleryImages/111.jpg";
import I112 from "./GalleryImages/112.jpg";
import I113 from "./GalleryImages/113.jpg";
import I114 from "./GalleryImages/114.jpg";
import I115 from "./GalleryImages/115.jpg";
import I116 from "./GalleryImages/116.jpg";
import I117 from "./GalleryImages/117.jpg";
import I118 from "./GalleryImages/118.jpg";
import I119 from "./GalleryImages/119.jpg";
import I120 from "./GalleryImages/120.jpg";
import I121 from "./GalleryImages/121.jpg";
import I122 from "./GalleryImages/122.jpg";
import I123 from "./GalleryImages/123.jpg";
import I124 from "./GalleryImages/124.jpg";
import I125 from "./GalleryImages/125.jpg";
import I126 from "./GalleryImages/126.jpg";
import I127 from "./GalleryImages/127.jpg";
import I128 from "./GalleryImages/128.jpg";
import I129 from "./GalleryImages/129.jpg";
import I130 from "./GalleryImages/130.jpg";
import I131 from "./GalleryImages/131.jpg";
import I132 from "./GalleryImages/132.jpg";
import I133 from "./GalleryImages/133.jpg";
import I134 from "./GalleryImages/134.jpg";
import I135 from "./GalleryImages/135.jpg";
import I136 from "./GalleryImages/136.jpg";
import I137 from "./GalleryImages/137.jpg";
import I138 from "./GalleryImages/138.jpg";
import I139 from "./GalleryImages/139.jpg";
import I140 from "./GalleryImages/140.jpg";
import I141 from "./GalleryImages/141.jpg";
import I142 from "./GalleryImages/142.jpg";
import I143 from "./GalleryImages/143.jpg";
import I144 from "./GalleryImages/144.jpg";
import I145 from "./GalleryImages/145.jpg";
import I146 from "./GalleryImages/146.jpg";
import I147 from "./GalleryImages/147.jpg";
import I148 from "./GalleryImages/148.jpg";
import I149 from "./GalleryImages/149.jpg";
import I150 from "./GalleryImages/150.jpg";
import I151 from "./GalleryImages/151.jpg";
import I152 from "./GalleryImages/152.jpg";
import I153 from "./GalleryImages/153.jpg";
import I154 from "./GalleryImages/154.jpg";
import I155 from "./GalleryImages/155.jpg";
import I156 from "./GalleryImages/156.jpg";
import I157 from "./GalleryImages/157.jpg";
import I158 from "./GalleryImages/158.jpg";
import I159 from "./GalleryImages/159.jpg";
import I160 from "./GalleryImages/160.jpg";
import I161 from "./GalleryImages/161.jpg";

export const imageGallery = [
  {
    id: 1,
    src: I1,
    alt: "Image description 1", category: 'fruit',
  },
  {
    id: 2,
    src: I2,
    alt: "Image description 2", category: 'coffees',
  },
  {
    id: 3,
    src: I3,
    alt: "Image description 3", category: 'fruit',
  },
  {
    id: 4,
    src: I4,
    alt: "Image description 4", category: 'fruit',
  },
  {
    id: 5,
    src: I5,
    alt: "Image description 5", category: 'fruit',
  },
  {
    id: 6,
    src: I6,
    alt: "Image description 6", category: 'colddrink',
  },
  {
    id: 7,
    src: I7,
    alt: "Image description 7", category: 'fruit',
  },
  {
    id: 8,
    src: I8,
    alt: "Image description 8", category: 'colddrink',
  },
  {
    id: 9,
    src: I9,
    alt: "Image description 9", category: 'fruit',
  },
  {
    id: 10,
    src: I10,
    alt: "Image description 10", category: 'colddrink',
  },
  {
    id: 11,
    src: I11,
    alt: "Image description 11", category: 'colddrink',
  },
  {
    id: 12, src: I12, alt: "Image description 12", category: 'colddrink',
  },
  {
    id: 13, src: I13, alt: "Image description 13", category: 'colddrink',
  },
  {
    id: 14, src: I14, alt: "Image description 14", category: 'colddrink',
  },
  {
    id: 15, src: I15, alt: "Image description 15", category: 'colddrink',
  },
  {
    id: 16,
    src: I16,
    alt: "Image description 16", category: 'colddrink',
  },
  {
    id: 17,
    src: I17,
    alt: "Image description 17", category: 'veg',
  },
  {
    id: 18,
    src: I18,
    alt: "Image description 18", category: 'colddrink',
  },
  {
    id: 19,
    src: I19,
    alt: "Image description 19",category: 'colddrink',
  },
  {
    id: 20,
    src: I20,
    alt: "Image description 20", category: 'coffees',
  },
  {
    id: 21,
    src: I21,
    alt: "Image description 21",category: 'colddrink',
  },
  {
    id: 22,
    src: I22,
    alt: "Image description 22", category: 'coffees',
  },
  {
    id: 23,
    src: I23,
    alt: "Image description 23", category: 'coffees',
  },
  {
    id: 24,
    src: I24,
    alt: "Image description 24", category: 'coffees',
  },
  {
    id: 25,
    src: I25,
    alt: "Image description 25",category: 'colddrink',
  },
  {
    id: 26,
    src: I26,
    alt: "Image description 26", category: 'coffees',
  },
  {
    id: 27,
    src: I27,
    alt: "Image description 27", category: 'coffees',
  },
  {
    id: 28,
    src: I28,
    alt: "Image description 28",category: 'colddrink',
  },
  {
    id: 29,
    src: I29,
    alt: "Image description 29",category: 'colddrink',
  },
  {
    id: 30,
    src: I30,
    alt: "Image description 30", category: 'veg',
  },
  {
    id: 31,
    src: I31,
    alt: "Image description 31",category: 'colddrink',
  },
  {
    id: 32,
    src: I32,
    alt: "Image description 32",category: 'colddrink',
  },
  {
    id: 33,
    src: I33,
    alt: "Image description 33", category: 'veg',
  },
  {
    id: 34, src: I34, alt: "Image description 34", category: 'veg',
  },
  {
    id: 35, src: I35, alt: "Image description 35", category: 'veg',
  },
  {
    id: 36, src: I36, alt: "Image description 36", category: 'veg',
  },
  {
    id: 37, src: I37, alt: "Image description 37", category: 'veg',
  },
  {
    id: 38, src: I38, alt: "Image description 38", category: 'veg',
  },
  {
    id: 39, src: I39, alt: "Image description 39", category: 'veg',
  },
  {
    id: 40, src: I40, alt: "Image description 40", category: 'veg',
  },
  {
    id: 41, src: I41, alt: "Image description 41", category: 'veg',
  },
  {
    id: 42, src: I42, alt: "Image description 42", category: 'veg',
  },
  {
    id: 43, src: I43, alt: "Image description 43", category: 'veg',
  },
  {
    id: 44, src: I44, alt: "Image description 44", category: 'veg',
  },
  {
    id: 45, src: I45, alt: "Image description 45", category: 'veg',
  },
  {
    id: 46, src: I46, alt: "Image description 46", category: 'veg',
  },
  {
    id: 47, src: I47, alt: "Image description 47", category: 'coffees',
  },
  {
    id: 48, src: I48, alt: "Image description 48", category: 'coffees',
  },
  {
    id: 49, src: I49, alt: "Image description 49", category: 'coffees',
  },
  {
    id: 50, src: I50, alt: "Image description 50", category: 'coffees',
  },
  {
    id: 51, src: I51, alt: "Image description 51", category: 'coffees',
  },
  {
    id: 52, src: I52, alt: "Image description 52", category: 'coffees',
  },
  {
    id: 53, src: I53, alt: "Image description 53", category: 'coffees',
  },
  {
    id: 54, src: I54, alt: "Image description 54", category: 'coffees',
  },
  {
    id: 55, src: I55, alt: "Image description 55", category: 'coffees',
  },
  {
    id: 56, src: I56, alt: "Image description 56", category: 'coffees',
  },
  {
    id: 57, src: I57, alt: "Image description 57", category: 'coffees',
  },
  {
    id: 58, src: I58, alt: "Image description 58", category: 'coffees',
  },
  {
    id: 59, src: I59, alt: "Image description 59", category: 'coffees',
  },
  {
    id: 60, src: I60, alt: "Image description 60", category: 'coffees',
  },
  {
    id: 61, src: I61, alt: "Image description 61", category: 'coffees',
  },
  {
    id: 62, src: I62, alt: "Image description 62", category: 'coffees',
  },
  {
    id: 63, src: I63, alt: "Image description 63", category: 'coffees',
  },
  {
    id: 64, src: I64, alt: "Image description 64", category: 'coffees',
  },
  {
    id: 65, src: I65, alt: "Image description 65", category: 'coffees',
  },
  {
    id: 66, src: I66, alt: "Image description 66", category: 'coffees',
  },
  {
    id: 67, src: I67, alt: "Image description 67", category: 'coffees',
  },
  {
    id: 68, src: I68, alt: "Image description 68", category: 'coffees',
  },
  {
    id: 69, src: I69, alt: "Image description 69", category: 'coffees',
  },
  {
    id: 70, src: I70, alt: "Image description 70", category: 'coffees',
  },
  {
    id: 71, src: I71, alt: "Image description 71", category: 'coffees',
  },
  {
    id: 72, src: I72, alt: "Image description 72", category: 'coffees',
  },
  {
    id: 73, src: I73, alt: "Image description 73", category: 'fruit',
  },
  {
    id: 74, src: I74, alt: "Image description 74",category: 'fruit',
  },
  {
    id: 75, src: I75, alt: "Image description 75",category: 'fruit',
  },
  {
    id: 76, src: I76, alt: "Image description 76",category: 'fruit',
  },
  {
    id: 77, src: I77, alt: "Image description 77",category: 'fruit',
  },
  {
    id: 78, src: I78, alt: "Image description 78",category: 'fruit',
  },
  {
    id: 79, src: I79, alt: "Image description 79",category: 'fruit',
  },
  {
    id: 80, src: I80, alt: "Image description 80",category: 'fruit',
  },
  {
    id: 81, src: I81, alt: "Image description 81",category: 'fruit',
  },
  {
    id: 82, src: I82, alt: "Image description 82",category: 'fruit',
  },
  {
    id: 83, src: I83, alt: "Image description 83",category: 'fruit',
  },
  {
    id: 84, src: I84, alt: "Image description 84",category: 'fruit',
  },
  {
    id: 85, src: I85, alt: "Image description 85",category: 'fruit',
  },
  {
    id: 86, src: I86, alt: "Image description 86",category: 'fruit',
  },
  {
    id: 87, src: I87, alt: "Image description 87",category: 'fruit',
  },
  {
    id: 88, src: I88, alt: "Image description 88",category: 'fruit',
  },
];
