import React, { useEffect, useRef, useState } from "react";
import "../styles/style.css";
import Footer from "./Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { DatePicker } from "@mui/x-date-pickers";
import { addReservation } from "../store/slice/reservationsSlice";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";

const Reservation = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };
  const [scrolled, setScrolled] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const handleSubMenuClick = (index) => (e) => {
    e.preventDefault();
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  // Function to handle scroll event
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => setIsMenuOpen(!isMenuOpen);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const [showAnimation, setShowAnimation] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const checkPosition = () => {
      if (!elementRef.current) {
        // Element is not yet available in the DOM, exit early
        return;
      }

      const element = elementRef.current;
      const elementPosition = element.getBoundingClientRect().top;
      const viewportHeight = window.innerHeight;

      // Example: Trigger animation when element is in the viewport
      if (elementPosition < viewportHeight) {
        setShowAnimation(true);
      } else {
        setShowAnimation(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", checkPosition);

    // Check position initially in case the element is already in view on initial load
    checkPosition();

    // Clean up
    return () => window.removeEventListener("scroll", checkPosition);
  }, []);

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageLoadError, setImageLoadError] = useState(false);

  const openLightbox = (images) => {
    setCurrentImageIndex(images);
    setIsLightboxOpen(true);
    setImageLoadError(false);
  };

  const handleImageError = () => {
    setImageLoadError(true);
  };

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const [isVisible, setIsVisible] = useState(false);

  // Function to check the scroll position
  const checkScrollTop = () => {
    if (!isVisible && window.pageYOffset > 400) {
      setIsVisible(true);
    } else if (isVisible && window.pageYOffset <= 400) {
      setIsVisible(false);
    }
  };

  // Function to scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  });

  // Form

  const [formErrors, setFormErrors] = useState({});

  const [formValues, setFormValues] = useState({
    studentid: "",
    studentname: "",
    studentemail: "",
    studentclass: "",
    mobile: "",
    date: "",
    occasion: "",
    numberOfPeople: "",
    time: {
      from: "",
      to: "",
    },
    // timeFrom: "",
    // timeTo: "",
    note: "",
    status: "pending",
  });
  const getTodayString = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();

    return `${dd}-${mm}-${yyyy}`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    if (name === "timeFrom" || name === "timeTo") {
      const timeKey = name === "timeFrom" ? "from" : "to";
      setFormValues((prevState) => ({
        ...prevState,
        time: {
          ...prevState.time,
          [timeKey]: value,
        },
      }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.studentid) {
      errors.studentid = "Student Id is required";
    }
    if (!values.studentname) {
      errors.studentname = "Name is required";
    }
    if (!values.mobile) {
      errors.mobile = "Mobile Number is required";
    }
    if (!values.date) {
      errors.date = "Date is required";
    }
    if (!values.occasion) {
      errors.occasion = "Occassion is required";
    }
    if (!values.timeFrom) {
      errors.timeFrom = "timeFrom is required";
    }
    if (!values.timeTo) {
      errors.timeTo = "timeTo is required";
    }

    return errors;
  };

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const errors = validate(formValues);
      if (Object.keys(errors).length === 0) {
        const payload = {
          studentid: formValues.studentid,
          studentname: formValues.studentname,
          studentemail: formValues.studentemail,
          studentclass: formValues.studentclass,
          mobile: formValues.mobile,
          reservationdate: new Date(formValues.date).toISOString(),
          numberOfPeople: formValues.numberOfPeople,
          occasion: formValues.occasion,
          time: {
            from: formValues.time.from,
            to: formValues.time.to,
          },
          note: formValues.note,
          status: "Pending", // Assuming this is set as "Pending" by default or based on some condition
        };

        console.log("Sending payload:", payload);

        dispatch(addReservation(payload))
          .unwrap()
          .then((response) => {
            toast.success("Reservation complete!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // Reset form here if necessary
            setFormValues({
              studentid: "",
              studentname: "",
              studentemail: "",
              studentclass: "",
              mobile: "",
              date: "",
              occasion: "",
              numberOfPeople: "",
              time: {
                from: "",
                to: "",
              },
              note: "",
              status: "pending",
            });
          })
          .catch((error) => {
            console.error("Failed to submit reservation:", error);
            toast.error(
              `Error: ${
                error.message || "Submission failed. Try again later."
              }`,
              {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          });
      } else {
        setFormErrors(errors);
      }
    } catch (error) {
      console.log("error :>> ", error.message);
    } finally {
      setLoading(false);
    }
  };

  // Login section

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState("");
  // const navigate = useNavigate();
  const [loginInfo, setLoginInfo] = useState({ username: "", password: "" });
  const [error, setError] = useState("");

  // Dummy credentials
  const DUMMY_USER = [
    {
      username: "Admin",
      password: "Admin@pc10",
      role: "admin",
    },
    {
      username: "Student",
      password: "1",
      role: "student",
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginInfo({ ...loginInfo, [name]: value });
    setError(""); // Reset error message on input change
  };

  const handleOpenLogin = () => {
    setIsLoggedIn(true);
  };

  // const handleLoginSubmit = (e) => {
  //   e.preventDefault();
  //   const { username, password } = e.target.elements;

  //   // if (validateLogin()) {
  //   //   setIsLoggedIn(true);
  //   // } else {
  //   //   setError("Invalid Id or password");
  //   // }
  //   const user = DUMMY_USER.find(user =>
  //     user.username === username.value && user.password === password.value
  //   );
  //   if (user ) {
  //     if (DUMMY_USER.role === 'admin') {
  //       navigate('/admin'); // Navigate to the admin page for admin users
  //     } else {
  //       setIsLoggedIn(true); // Show the second section for non-admin users (e.g., students)
  //       setError("Invalid Id or password");
  //     }
  //   } else {
  //     // Handle login failure (show error, etc.)
  //     console.error('Authentication failed');
  //   }
  // };

  const validateLogin = () => {
    return (
      loginInfo.username === DUMMY_USER.username &&
      loginInfo.password === DUMMY_USER.password
    );
  };

  const handleLoginSubmit = (event) => {
    event.preventDefault();

    const { username, password } = event.target.elements;
    const user = DUMMY_USER.find(
      (u) => u.username === username.value && u.password === password.value
    );

    if (validateLogin()) {
      localStorage.setItem("loginSuccess", "true");
      console.log(localStorage.getItem("loginSuccess"));
      // navigate('/admin');
    } else {
      setError("Invalid username or password");
    }

    if (user) {
      setUserRole(user.role); // Set the user role on successful login
      localStorage.setItem("loginSuccess", "true");
      localStorage.setItem("userRole", user.role);
      if (user.role === "admin") {
        navigate("/admin");
        toast.success("Login successful!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      // else if (user.role === "student") {
      //   setIsLoggedIn(true);
      // }
    } else {
      setError("Invalid username or password");
      toast.error("Authentication failed", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    // if (user) {
    //   setUserRole(user.role); // Set the user role on successful login
    //   localStorage.setItem("loginSuccess", "true");
    //   console.log(localStorage.getItem("loginSuccess"));
    //   if (user.role === "admin") {
    //     navigate("/admin");
    //   } else if (user.role === "student") {
    //     // setIsLoggedIn(true);
    //     toast.success("Login successful!", {
    //       position: "top-right",
    //       autoClose: 2000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   }
    // } else {
    //   console.error("Authentication failed");
    //   toast.error("Authentication failed", {
    //     position: "top-right",
    //     autoClose: 2000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  };

  useEffect(() => {
    if (localStorage.getItem("loginSuccess") === "true") {
      setIsLoggedIn(true);
      setUserRole(localStorage.getItem("userRole"));
    }
  }, []);

  const renderStudentSection = () => {
    return (
      <>
        <section className="topSingleBkg topPageBkg" style={{ height: "13vh" }}>
          <div className="item-content-bkg">
            <div
              className="item-img"
              style={{
                backgroundImage:
                  'url("images/top-headers/reservation-image.jpg")',
              }}
            />
          </div>
        </section>

        <section id="wrap-content" className="page-content">
          <div className="login-page pt-0 mb-5">
            <div className="form">
              <div className="brand">
                <img src="images/PremierChef.png" alt="brand" />
                <h4 className="mb-0">Premier Chef Catering</h4>
              </div>
              <form className="login-form" onSubmit={handleLoginSubmit}>
                <input
                  type="text"
                  placeholder="Id"
                  name="username"
                  value={loginInfo.username}
                  onChange={handleInputChange}
                />
                <input
                  type="password"
                  placeholder="password"
                  name="password"
                  value={loginInfo.password}
                  onChange={handleInputChange}
                />
                {error && <div className="error">{error}</div>}
                <button type="submit">login</button>
                <p className="message">
                  Not reservation?{" "}
                  <a href="#" style={{ color: "#bc8d4b" }}>
                    click here
                  </a>
                </p>
              </form>
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <div>
      <div>
        {/* MOBILE MENU */}
        {isActive && (
          <div className="mask-nav-2 is-active">
            {/* MENU */}
            <nav className="navbar navbar-2 nav-mobile">
              <div className="nav-holder nav-holder-2 ">
                <ul id="menu-menu-2" className="menu-nav-2">
                  <li
                    className={`menu-item  ${
                      activeSubMenu === 1 ? "menu-open" : ""
                    }`}
                  >
                    <a href="/">Home</a>
                  </li>
                  <li className="menu-item">
                    <a href="/Menu">Menu</a>
                  </li>

                  <li className="menu-item">
                    <a href="/reservation">Reservation</a>
                  </li>
                  <li className="menu-item ">
                    <a href="/gallery">Gallery</a>
                  </li>

                  <li className="menu-item ">
                    <a href="/Contact">Contact</a>
                  </li>
                </ul>
              </div>
            </nav>
            {/* /MENU */}
          </div>
        )}
        {/* /MOBILE MENU */}
        {/* HEADER */}
        <header id="header-1">
          <div className="headerWrap-1">
            <nav className="navbar-1">
              {/* TOP LEFT PAGE TEXT  */}
              <div className="top-location">
                <span className="info-txt">
                  <a href="/" style={{ position: "relative", bottom: "0" }}>
                    <img
                      // className="img-fluid"
                      src="images/Premier-chef-logo.png"
                      alt="logo"
                      style={{ height: "100px" }}
                    />
                  </a>{" "}
                </span>

                {/* <span className="info-txt">New York 70250</span>    */}
              </div>

              {/* MOBILE BUTTON NAV  */}
              <div
                className={`nav-button-holder nav-btn-mobile inactive ${
                  isActive ? "active" : "inactive"
                }`}
                onClick={toggleMenu}
                style={{ marginTop: "5px" }}
              >
                {/* <span className="menu-txt" style={{ fontSize: "18px" }}>
                  MENU
                </span> */}
                <button className="nav-button">
                  <span
                    className="fa fa-bars"
                    style={{ color: "white", fontSize: "30px" }}
                  />
                </button>
              </div>
              <div className="nav-content-1">
                {/* LOGO */}
                <div className="logo-1">
                  <a href="/" className="navbar-brand p-0">
                    <img
                      className="img-fluid"
                      src="images/Premier-chef-logo.png"
                      alt="logo"
                      style={{ height: "80px" }}
                    />
                  </a>
                </div>
                {/* MENU */}
                <div className="nav-holder nav-holder-1 nav-holder-desktop">
                  <ul
                    id="menu-menu-1"
                    className="menu-nav menu-nav-1"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <li className="menu-item ">
                      <a href="/">Home</a>
                    </li>
                    <li className="menu-item ">
                      <a href="/menu" onMouseEnter={handleMouseEnter}>
                        Menu
                      </a>
                    </li>
                    {/* menu-item-has-children  */}
                    <li className="menu-item current-menu-item">
                      <a href="/reservation">Reservation</a>
                    </li>
                    <li className="menu-item ">
                      <a href="/gallery">Gallery</a>
                    </li>

                    <li className="menu-item ">
                      <a href="/contact">Contact</a>
                    </li>
                    {/* <li className="menu-item ">
                      <a><GoogleTranslate/></a>
                    </li> */}
                  </ul>
                </div>
                {/* /MENU */}
              </div>
            </nav>
          </div>
          {/*headerWrap*/}
        </header>
        {/* /HEADER */}
      </div>

      {/* {isHovered && (
        <div
          ref={elementRef}
          className={`menu animated ${
            showAnimation ? "animatedFadeInUp fadeInUp" : ""
          }`}
          onMouseLeave={handleMouseLeave}
        >
          <nav className={`menu-content ${isMenuOpen ? "active" : ""}`}>
            <div className="menu-holder menu-1col header-menu-accordion">
              <h4>
                <span className="header-menu-title">HOT COFFEES</span>
              </h4>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width={400}
                    height={400}
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f12bc8af498d45ed92c5d6f1dac64062_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>

                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFE AMERICANO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Espresso shots topped with hot water{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/5c515339667943ce84dc56effdf5fc1b_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAPPUCCINO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Dark espresso lies in wait under milk foam{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/58db701349cb48738069e8c912e2b3ac_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CARAMEL MACCHIATO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Freshly steamed milk with vanilla-flavored syrup{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f4ec500b34624242b15c2d29e53f3c48_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFFE MOCHA</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$7.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Full-bodied espresso combined with mocha{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-holder menu-1col header-menu-accordion">
              <h4>
                <span className="header-menu-title">COLD COFFEES</span>
              </h4>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width={400}
                    height={400}
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f12bc8af498d45ed92c5d6f1dac64062_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>

                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFE AMERICANO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Espresso shots topped with hot water{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/5c515339667943ce84dc56effdf5fc1b_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAPPUCCINO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Dark espresso lies in wait under milk foam{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/58db701349cb48738069e8c912e2b3ac_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CARAMEL MACCHIATO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Freshly steamed milk with vanilla-flavored syrup{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f4ec500b34624242b15c2d29e53f3c48_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFFE MOCHA</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$7.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Full-bodied espresso combined with mocha{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-holder menu-1col header-menu-accordion">
              <h4>
                <span className="header-menu-title">BAKERY</span>
              </h4>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width={400}
                    height={400}
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f12bc8af498d45ed92c5d6f1dac64062_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>

                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFE AMERICANO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Espresso shots topped with hot water{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/5c515339667943ce84dc56effdf5fc1b_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAPPUCCINO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Dark espresso lies in wait under milk foam{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/58db701349cb48738069e8c912e2b3ac_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CARAMEL MACCHIATO</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$9.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Freshly steamed milk with vanilla-flavored syrup{" "}
                  </div>
                </div>
              </div>
              <div className="header-menu-post clearfix">
                <div className="headermenu-img">
                  <img
                    width="400"
                    height="400"
                    src="https://craftcoffee-v1.b-cdn.net/craft/wp-content/uploads/2020/05/f4ec500b34624242b15c2d29e53f3c48_result-150x150.jpg"
                    alt="Tomato Bruschetta"
                    title="Tomato Bruschetta"
                  />
                </div>
                <div className="header-menu-post-desc">
                  <h4>
                    <span className="menu-title">CAFFE MOCHA</span>
                    <span className="menu-dots"></span>
                    <span className="menu-price">$7.00</span>
                  </h4>
                  <div className="header-menu-text">
                    {" "}
                    Full-bodied espresso combined with mocha{" "}
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      )} */}

      {/* <section className="topSingleBkg topPageBkg" >
        <div className="item-content-bkg">
          <div
            className="item-img"
            style={{
              backgroundImage:
                'url("images/top-headers/reservation-image.jpg")',
            }}
          />
          <div className="inner-desc">
            <h1 className="post-title single-post-title">Reservation</h1>
            <span className="post-subtitle"> We love to hear from you </span>
          </div>
        </div>
      </section> */}

      {!isLoggedIn ? (
        <>
          <section
            className="topSingleBkg topPageBkg"
            style={{ height: "17vh" }}
          >
            <div className="item-content-bkg">
              <div
                className="item-img"
                style={{
                  backgroundImage:
                    'url("images/top-headers/reservation-image.jpg")',
                }}
              />
              {/* <div className="inner-desc">
              <h1 className="post-title single-post-title">Reservation</h1>
              <span className="post-subtitle"> We love to hear from you </span>
            </div> */}
            </div>
          </section>

          <section id="wrap-content" className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-holder custom-page-template">
                    <div className="row">
                      {/* LEFT CONTENT */}
                      <div
                        className="col-md-12 d-flex"
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div className="headline text-center">
                          <h2 onClick={handleOpenLogin}>Reservation</h2>
                        </div>
                        <div id="contact-form-holder">
                          <form className="form mb-5" onSubmit={handleSubmit}>
                            <div className="form-row">
                              <div className="form-group col-md-3 mb-0">
                                <label>StudentID:</label>
                                <input
                                  type="text"
                                  name="studentid"
                                  value={formValues.studentid}
                                  onChange={handleChange}
                                />
                                {formErrors.studentid && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.studentid}
                                  </span>
                                )}
                              </div>
                              <div className="form-group mb-0">
                                <label>Name:</label>
                                <input
                                  type="text"
                                  name="studentname"
                                  value={formValues.studentname}
                                  onChange={handleChange}
                                />
                                {formErrors.studentname && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.studentname}
                                  </span>
                                )}
                              </div>

                              <div className="form-group col-md-8 mb-0">
                                <label>Email:</label>
                                <input
                                  // className="reservation-fields"
                                  type="email"
                                  name="studentemail"
                                  value={formValues.studentemail}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group mb-0">
                                <label>Class:</label>
                                <input
                                  type="number"
                                  name="studentclass"
                                  value={formValues.studentclass}
                                  onChange={handleChange}
                                  min="1"
                                />
                              </div>

                              <div className="form-group col-md-8 mb-0">
                                <label>Mobile:</label>
                                <input
                                  type="number"
                                  name="mobile"
                                  value={formValues.mobile}
                                  onChange={handleChange}
                                  min="1"
                                />
                                {formErrors.mobile && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.mobile}
                                  </span>
                                )}
                              </div>

                              <div className="form-group mb-0">
                                <label>People:</label>
                                <input
                                  type="number"
                                  name="numberOfPeople"
                                  value={formValues.numberOfPeople}
                                  onChange={handleChange}
                                  min="1"
                                />
                              </div>

                              <div className="form-group col-md-5 mb-0">
                                <label>Date:</label>
                                <input
                                  type="date"
                                  name="date"
                                  // dateFormat="dd/MM/yyyy"
                                  format="D/M/YYYY"
                                  value={formValues.date}
                                  onChange={handleChange}
                                  min={getTodayString()}
                                />
                                {formErrors.date && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.date}
                                  </span>
                                )}
                              </div>
                              <div className="form-group mb-0">
                                <label>Occasion:</label>
                                {/* <input
                                type="text"
                                name="occasion"
                                value={formValues.occasion}
                                onChange={handleChange}
                              /> */}
                                <input
                                  // className="reservation-fields"
                                  type="text"
                                  name="occasion"
                                  // id="exampleFormControlSelect1"
                                  value={formValues.occasion}
                                  onChange={handleChange}
                                />
                                {formErrors.occasion && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.occasion}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="form-row">
                              <div className="form-group mb-0">
                                <label>Time From:</label>
                                <input
                                  type="time"
                                  name="timeFrom"
                                  value={formValues.time.from}
                                  onChange={handleChange}
                                />
                                {formErrors.timeFrom && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.timeFrom}
                                  </span>
                                )}
                              </div>
                              <div className="form-group mb-0">
                                <label>Time To:</label>
                                <input
                                  type="time"
                                  name="timeTo"
                                  value={formValues.time.to}
                                  onChange={handleChange}
                                />
                                {formErrors.timeTo && (
                                  <span style={{ color: "red" }}>
                                    {formErrors.timeTo}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form-group mb-0">
                              <label>Note:</label>
                              <textarea
                                name="note"
                                value={formValues.note}
                                onChange={handleChange}
                              ></textarea>
                            </div>
                            <button
                              // type="submit"
                              // defaultValue="Send message"
                              // id="submit"
                              disabled={loading}
                            >
                              {loading ? (
                                <CircularProgress size={24} sx={{color: "white"}}/>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </form>
                        </div>
                        <div id="output-contact" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>{renderStudentSection()}</>
      )}

      <Footer />
    </div>
  );
};

export default Reservation;
