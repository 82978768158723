import React, { useEffect, useMemo, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/style.css";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { Visibility as VisibilityIcon } from "@material-ui/icons";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
} from "@mui/material";
import useWindowSize from "../../hooks/useWindowSize";
import "dayjs/locale/en-gb";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getReservationList,
  updateReservation,
} from "../../store/slice/reservationsSlice";

const AllHistory = () => {
  const { width } = useWindowSize();
  const isMobile = width < 768;

  const [selectedDate, setSelectedDate] = useState(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState({});
  const [reservationsData, setReservationsData] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // API Logic
  // useEffect(() => {
  //   fetchReservations();
  // }, []);

  // const fetchReservations = async () => {
  //   const response = await fetch("http://localhost:3030/api/list-reservations");
  //   const data = await response.json();
  //   setReservationsData(data.data);
  //   // console.log("data :>> ", data.data);
  // };

  const dispatch = useDispatch();
  // const { reservations, loading, error } = useSelector(state => state.reservation);
  const { reservations, loading } = useSelector(
    (state) => state.reservation || { reservations: [], loading: false }
  );

  // console.log('reservations :>> ', reservations);

  // Fetch data on component mount
  useEffect(() => {
    if (!loading && reservations.length === 0) {
      // Conditional fetching
      dispatch(getReservationList());
    }
  }, [dispatch, loading, reservations.length]);

  // Fillter and sort data based on reservationsDate
  const filteredAndSortedData = useMemo(() => {
    if (!reservations) return [];

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to midnight for comparison

    // Determine start and end dates for filtering
    const start = startDate ? new Date(startDate).setHours(0, 0, 0, 0) : null;
    const end = endDate ? new Date(endDate).setHours(23, 59, 59, 999) : null;

    const filteredData = reservations.filter((reservation) => {
      const reservationDate = new Date(reservation.reservationdate);
      reservationDate.setUTCHours(0, 0, 0, 0);

      return (
        (!start || reservationDate >= start) && (!end || reservationDate <= end)
      );
    });

    // console.log('filteredData :>> ', filteredData);

    return filteredData.sort(
      (a, b) => new Date(a.reservationdate) - new Date(b.reservationdate)
    );
  }, [reservations, startDate, endDate]);

  const columns = [
    {
      name: "Student ID",
      selector: (row) => row.studentid,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => formatDate(row.reservationdate),
      sortable: true,
    },
    {
      name: "Time",
      selector: (row) =>
        `${formatTime24To12(row.time.from)} - ${formatTime24To12(row.time.to)}`,
      sortable: true,
    },
    {
      name: "Occasion",
      selector: (row) => row.occasion,
      sortable: true,
    },
    // {
    //   name: "Status",
    //   cell: (row) => {
    //     let IconComponent;
    //     let iconColor = "default";
    //     switch (row.status) {
    //       case "Confirmed":
    //         IconComponent = CheckBoxIcon;
    //         iconColor = "green";
    //         break;
    //       case "Cancelled":
    //         IconComponent = CancelIcon;
    //         iconColor = "red";
    //         break;
    //       case "Pending":
    //         IconComponent = PendingActionsIcon;
    //         iconColor = "black";
    //         break;
    //       default:
    //         // Optionally handle unexpected statuses
    //         IconComponent = null;
    //     }

    //     return (
    //       <IconButton>
    //         {IconComponent ? (
    //           <IconComponent style={{ color: iconColor }} />
    //         ) : null}
    //       </IconButton>
    //     );
    //   },
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
    {
      name: "Action",
      cell: (row) => {
        let IconComponent;
        let iconColor = "default";
        switch (row.status) {
          case "Confirmed":
            IconComponent = CheckBoxIcon;
            iconColor = "green";
            break;
          case "Cancelled":
            IconComponent = CancelIcon;
            iconColor = "red";
            break;
          case "Pending":
            IconComponent = PendingActionsIcon;
            iconColor = "black";
            break;
          default:
            // Optionally handle unexpected statuses
            IconComponent = null;
        }

        return (
          <>
            <IconButton>
              {IconComponent ? (
                <IconComponent style={{ color: iconColor }} />
              ) : null}
            </IconButton>
            <IconButton onClick={() => handleView(row._id)}>
              <VisibilityIcon />
            </IconButton>
          </>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const columnsToShow =
    width < 768
      ? columns.filter((column) =>
          ["Student ID", "Date", "Action"].includes(column.name)
        )
      : columns;

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgba(33, 37, 41, 0.19)",
        borderBottomColor: "#FFFFFF",
        borderRadius: "25px",
        outline: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  const handleView = (id) => {
    // console.log("Viewing details for ID:", id);

    const reservation = reservations.find(
      (reservation) => reservation._id === id
    );
    setSelectedReservation(reservation);
    setIsDrawerOpen(true);
  };

  // useEffect(() => {
  //   // Check if the loginSuccess flag is set in local storage
  //   if (localStorage.getItem("loginSuccess") === "true") {
  //     // console.log(localStorage.getItem("loginSuccess"));
  //     toast.success("Login successful!", {
  //       position: "top-right",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     localStorage.removeItem("loginSuccess");
  //   }
  // }, []);

  useEffect(() => {
    if (selectedDate) {
      const filteredData = reservationsData.filter((reservation) => {
        // Assuming `reservation.date` is in 'YYYY-MM-DD' format
        return (
          reservation.reservationdate === selectedDate.format("YYYY-MM-DD")
        );
      });
      setFilteredReservations(filteredData);
    } else {
      setFilteredReservations(reservationsData);
    }
  }, [selectedDate, reservationsData]);

  const [filteredReservations, setFilteredReservations] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // JavaScript months are 0-based.
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatTime24To12 = (time24) => {
    if (!time24) return ""; // handle undefined or null inputs
    const [hour, minute] = time24.split(":");
    const hourInt = parseInt(hour, 10);
    const ampm = hourInt >= 12 ? "PM" : "AM";
    const hour12 = hourInt % 12 === 0 ? 12 : hourInt % 12;
    return `${hour12}:${minute} ${ampm}`;
  };

  const filteredDatabyDate = reservationsData.filter((reservation) => {
    if (!startDate || !endDate) return true;
    const reservationDate = new Date(reservation.reservationdate);
    return (
      !startDate ||
      !endDate ||
      (reservationDate >= startDate && reservationDate <= endDate)
    );
  });

  const processData = () => {
    const filteredData = reservationsData.filter((reservation) => {
      if (!startDate || !endDate) {
        return true; // No filter applied
      }
      const reservationDate = new Date(reservation.reservationdate);
      return reservationDate >= startDate && reservationDate <= endDate;
    });

    return filteredData.sort((a, b) => {
      const dateA = new Date(a.reservationdate);
      const dateB = new Date(b.reservationdate);
      return dateA - dateB; // Sorting in ascending order
    });
  };

  // Process data: filter by date range and sort
  const processedData = useMemo(() => {
    return processData(reservationsData, startDate, endDate);
  }, [reservationsData, startDate, endDate]);

  const handleApproveReject = (id, action) => {
    const status = action === "approve" ? "Confirmed" : "Cancelled";
    console.log("id :>> ", id);

    // Optimistically update the local state
    setReservationsData((prevData) =>
      prevData.map((reservation) =>
        reservation._id === id ? { ...reservation, status } : reservation
      )
    );

    // Dispatch the update action to the server
    dispatch(updateReservation({ id, status }))
      .unwrap()
      .then((response) => {
        console.log("Update successful: :>> ", response);

        // Refresh the reservation list in the store
        dispatch(getReservationList());

        // Notify the user of the success
        toast.success(
          `Reservation ${action === "approve" ? "approved" : "rejected"}!`,
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );

        // Optionally, store the action message
        localStorage.setItem(
          "lastActionMessage",
          action === "approve"
            ? "Your reservation was approved."
            : "Your reservation was rejected."
        );
      })
      .catch((error) => {
        console.error("Error updating reservation:", error);

        // Notify the user of the failure
        toast.error("Failed to update the reservation.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        // Revert the optimistic update if the request fails
        setReservationsData((prevData) =>
          prevData.map((reservation) =>
            reservation._id === id
              ? {
                  ...reservation,
                  status: status === "Confirmed" ? "Pending" : "Confirmed",
                }
              : reservation
          )
        );
      });

    setIsDrawerOpen(false);
  };

  // Helper to reset date filters
  const handleResetFilter = () => {
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    handleResetFilter();
  }, []);

  const NoDataComponent = () => (
    <div style={{ padding: "20px", textAlign: "center" }}>
      No Any Reservations
    </div>
  );

  return (
    <div className="p-0 p-md-3 mt-5" id="History">
      <div
        className="date-picker d-flex justify-content-end mx-3"
        // style={{ marginBottom: "10px" }}
      >
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          isClearable
          placeholderText="Start Date"
        />
        &nbsp; &nbsp;
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          isClearable
          placeholderText="End Date"
        />
      </div>

      <DataTable
        title="All History"
        columns={columnsToShow}
        // data={reservations}
        data={loading ? [] : filteredAndSortedData}
        pagination
        noDataComponent={<NoDataComponent />}
        customStyles={customStyles}
        highlightOnHover
        pointerOnHover
      />

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        PaperProps={{
          style: {
            width: isMobile ? "80%" : "30%",
          },
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          // style={{ marginBottom: "5px" }}
          className="p-3"
        >
          Details
        </Typography>
        <hr className="text-secondary m-0" style={{ width: "100%" }} />
        <List>
          {/* <hr className="text-secondary" style={{ width: "100%" }} /> */}
          {selectedReservation?.studentid && (
            <ListItem sx={{ pb: 0 }}>
              <ListItemText
                // className="d-flex flex-row"
                primary="Student ID :"
                secondary={selectedReservation?.studentid}
              />
            </ListItem>
          )}

          {selectedReservation?.studentname && (
            <ListItem sx={{ pb: 0 }}>
              <ListItemText
                primary="Student Name :"
                secondary={selectedReservation?.studentname}
              />
            </ListItem>
          )}

          {selectedReservation?.studentemail && (
            <ListItem sx={{ pb: 0 }}>
              <ListItemText
                primary="Student Email :"
                secondary={selectedReservation?.studentemail}
              />
            </ListItem>
          )}

          {selectedReservation?.studentclass && (
            <ListItem sx={{ pb: 0 }}>
              <ListItemText
                primary="Class :"
                secondary={selectedReservation?.studentclass}
              />
            </ListItem>
          )}

          {selectedReservation?.mobile && (
            <ListItem sx={{ pb: 0 }}>
              <ListItemText
                primary="Mobile No :"
                secondary={selectedReservation?.mobile}
              />
            </ListItem>
          )}

          <ListItem sx={{ pb: 0 }}>
            <ListItemText
              primary="Date :"
              secondary={
                selectedReservation
                  ? formatDate(selectedReservation.reservationdate)
                  : "No date"
              }
            />
          </ListItem>

          <ListItem sx={{ pb: 0 }}>
            <ListItemText
              primary="Time :"
              secondary={`${formatTime24To12(
                selectedReservation?.time?.from
              )} - ${formatTime24To12(selectedReservation?.time?.to)}`}
            />
          </ListItem>

          {selectedReservation?.occasion && (
            <ListItem sx={{ pb: 0 }}>
              <ListItemText
                primary="Occasion :"
                secondary={selectedReservation?.occasion}
              />
            </ListItem>
          )}

          {selectedReservation?.numberOfPeople && (
            <ListItem sx={{ pb: 0 }}>
              <ListItemText
                primary="Number Of People :"
                secondary={selectedReservation?.numberOfPeople}
              />
            </ListItem>
          )}

          {selectedReservation?.note && (
            <ListItem sx={{ pb: 0 }}>
              <ListItemText
                primary="Note :"
                secondary={selectedReservation?.note}
              />
            </ListItem>
          )}

          {selectedReservation?.status === "Cancelled" && (
            <ListItem sx={{ pb: 0 }}>
              <Button
                variant="contained"
                color="primary"
                disabled={selectedReservation?.status !== "Cancelled"}
                onClick={() =>
                  handleApproveReject(selectedReservation?._id, "approve")
                }
                style={{ backgroundColor: "#bc8d4b" }}
              >
                Approve
              </Button>
            </ListItem>
          )}
        </List>
      </Drawer>
    </div>
  );
};

export default AllHistory;
