import React, { useEffect, useMemo, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/style.css";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { Visibility as VisibilityIcon } from "@material-ui/icons";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContent,
  Dialog,
  Box,
} from "@mui/material";
import useWindowSize from "../../hooks/useWindowSize";
import "dayjs/locale/en-gb";
import Reserved from "./Reserved";
import AllHistory from "./AllHistory";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getPendingReservationList,
  updateReservation,
} from "../../store/slice/reservationsSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { get, isEmpty, find, filter, has, debounce } from ‘loadash’;

const SidebarMenu = () => {
  const { width } = useWindowSize();
  const isMobile = width < 768;

  const [selectedDate, setSelectedDate] = useState(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState({});

  const [visibleSection, setVisibleSection] = useState("Pending ");

  const [reservedReservations, setReservedReservations] = useState([]);

  const [reservationsData, setReservationsData] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const dispatch = useDispatch();
  const { pendingReservations, loading } = useSelector(
    (state) => state.reservation || { pendingReservations: [], loading: false }
  );

  // console.log('pendingReservations :>> ', pendingReservations);

  // Fetch data on component mount
  useEffect(() => {
    dispatch(getPendingReservationList());
  }, [dispatch]);

  // Fillter and sort data based on reservationsDate
  // pass in Data Table
  const filteredAndSortedData = useMemo(() => {
    if (!pendingReservations) return [];

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to midnight for comparison

    // Determine start and end dates for filtering
    const start = startDate ? new Date(startDate).setHours(0, 0, 0, 0) : null;
    const end = endDate ? new Date(endDate).setHours(23, 59, 59, 999) : null;

    const filteredData = pendingReservations.filter((reservation) => {
      const reservationDate = new Date(reservation.reservationdate);
      reservationDate.setUTCHours(0, 0, 0, 0);

      return (
        (!start || reservationDate >= start) && (!end || reservationDate <= end)
      );
    });

    // console.log('filteredData :>> ', filteredData);
    return filteredData.sort(
      (a, b) => new Date(a.reservationdate) - new Date(b.reservationdate)
    );
  }, [pendingReservations, startDate, endDate]);

  const columns = [
    {
      name: "Student ID",
      selector: (row) => row.studentid,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => formatDate(row.reservationdate),
      sortable: true,
    },
    {
      name: "Time",
      selector: (row) =>
        `${formatTime24To12(row.time.from)} - ${formatTime24To12(row.time.to)}`,
      sortable: true,
    },
    {
      name: "Occasion",
      selector: (row) => row.occasion,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <IconButton onClick={() => handleView(row._id)}>
            <VisibilityIcon />
          </IconButton>
          <IconButton onClick={() => handleEdit(row)}>
            <EditRoundedIcon />
          </IconButton>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const columnsToShow =
    width < 768
      ? columns.filter((column) =>
          ["Student ID", "Date", "Action"].includes(column.name)
        )
      : columns;

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [currentReservation, setCurrentReservation] = useState(null);

  const handleEdit = (reservation) => {
    setCurrentReservation(reservation);
    setEditData(reservation);
    setEditModalOpen(true);
  };

  const [newDate, setNewDate] = useState("");
  const [newTimeFrom, setNewTimeFrom] = useState("");
  const [newTimeTo, setNewTimeTo] = useState("");

  const handleView = (id) => {
    console.log("Viewing details for ID:", id);

    const reservation = pendingReservations.find(
      (reservation) => reservation._id === id
    );
    setSelectedReservation(reservation);
    setIsDrawerOpen(true);
  };

  const handleApproveReject = (id, action) => {
    const status = action === "approve" ? "Confirmed" : "Cancelled";
    console.log("id :>> ", id);

    // Optimistically update the local state
    setReservationsData((prevData) =>
      prevData.map((reservation) =>
        reservation._id === id ? { ...reservation, status } : reservation
      )
    );

    // Dispatch the update action to the server
    dispatch(updateReservation({ id, status }))
      .unwrap()
      .then((response) => {
        console.log("Update successful: :>> ", response);

        // Refresh the reservation list in the store
        dispatch(getPendingReservationList());

        // Notify the user of the success
        toast.success(
          `Reservation ${action === "approve" ? "approved" : "rejected"}!`,
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );

        // Optionally, store the action message
        localStorage.setItem(
          "lastActionMessage",
          action === "approve"
            ? "Your reservation was approved."
            : "Your reservation was rejected."
        );
      })
      .catch((error) => {
        console.error("Error updating reservation:", error);

        // Notify the user of the failure
        toast.error("Failed to update the reservation.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        // Revert the optimistic update if the request fails
        setReservationsData((prevData) =>
          prevData.map((reservation) =>
            reservation._id === id
              ? {
                  ...reservation,
                  status: status === "Confirmed" ? "Pending" : "Confirmed",
                }
              : reservation
          )
        );
      });

    setIsDrawerOpen(false);
  };

  useEffect(() => {
    if (localStorage.getItem("loginSuccess") === "true") {
      console.log(localStorage.getItem("loginSuccess"));
      // toast.success("Login successful!", {
      //   position: "top-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    }
  }, []);

  useEffect(() => {
   
  }, [selectedDate, reservationsData, pendingReservations]);

  const [filteredReservations, setFilteredReservations] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatTime24To12 = (time24) => {
    if (!time24) return "";
    const [hour, minute] = time24.split(":");
    const hourInt = parseInt(hour, 10);
    const ampm = hourInt >= 12 ? "PM" : "AM";
    const hour12 = hourInt % 12 === 0 ? 12 : hourInt % 12;
    return `${hour12}:${minute} ${ampm}`;
  };

  // Edit Date and Time
  const submitEdit = async () => {
    console.log("Submitting new times:", newTimeFrom, newTimeTo);

    try {
      await dispatch(
        updateReservation({
          id: editData._id,
          // status: editData.status,
          newDate,
          newTimeFrom,
          newTimeTo,
        })
      );

      console.log("editData._id :>> ", editData._id);
      toast.success("Reservation updated successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setNewDate("");
      setNewTimeFrom("");
      setNewTimeTo("");

      dispatch(getPendingReservationList());
    } catch (error) {
      console.error("Failed to update reservation:", error);
      toast.error("Failed to update the reservation.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setEditModalOpen(false);
  };

  useEffect(() => {
    if (currentReservation) {
      setNewDate(currentReservation.reservationdate);
      setNewTimeFrom(currentReservation.time.from);
      setNewTimeTo(currentReservation.time.to);
    }
  }, [currentReservation]);

  const handleResetFilter = () => {
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    handleResetFilter();
  }, []);

  const state = useSelector((state) => state);

  // Optional: handle loading and error states
  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error}</div>;

  const handleFilterChange = (filteredData) => {
    setFilteredReservations(filteredData);
  };

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("loginSuccess");
    localStorage.removeItem("userRole");
    navigate("/reservation");
    toast.success("Log Out successful!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const NoDataComponent = () => (
    <div style={{ padding: "20px", textAlign: "center" }}>
      No Any Pending Reservations
    </div>
  );

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgba(33, 37, 41, 0.19)",
        borderBottomColor: "#FFFFFF",
        borderRadius: "25px",
        outline: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  return (
    <div className="container-fluid">
      <div className="row p-0">
        <div
          className="bg-dark col-auto col-md-3 col-lg-2 min-vh-100"
          style={{ width: "275px" }}
        >
          <div style={{ position: "fixed" }}>
            <h3
              // href="/admin"
              className="text-decoration-none text-white d-none d-sm-inline d-flex align-item-center ms-3"
            >
              {/* <i className="fs-4 bi bi-speedometer"></i> */}
              <span className="ms-1 fs-2">Premier Chef</span>
            </h3>
            <hr
              className="text-secondary d-none d-sm-block"
              style={{ width: "100%" }}
            />
            <ul className="nav nav-pills flex-column m-0 mt-3 mt-md-0 ">
              <li className="nav-item text-white fs-4 my-1 py-2 py-sm-0">
                <a
                  href="/admin/"
                  className="nav-link text-white fs-5"
                  aria-current="page"
                  onClick={(e) => {
                    e.preventDefault();
                    setVisibleSection("Pending ");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-hourglass-split"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                  </svg>
                  <span className="ms-3 d-none d-sm-inline">Pending </span>
                </a>
              </li>
              <li className="nav-item text-white fs-4 my-1 py-2 py-sm-0">
                <a
                  href="#"
                  className="nav-link text-white fs-5"
                  aria-current="page"
                  onClick={(e) => {
                    e.preventDefault();
                    setVisibleSection("Approved");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-card-checklist"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z" />
                    <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0M7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0" />
                  </svg>
                  <span className="ms-3 d-none d-sm-inline">Approved</span>
                </a>
              </li>
              <li className="nav-item text-white fs-4 my-1 py-2 py-sm-0">
                <a
                  href="#"
                  className="nav-link text-white fs-5"
                  aria-current="page"
                  onClick={(e) => {
                    e.preventDefault();
                    setVisibleSection("History");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-clock-history"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z" />
                    <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
                    <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5" />
                  </svg>
                  <span className="ms-3 d-none d-sm-inline">History</span>
                </a>
              </li>
              <li className="nav-item text-white fs-4 my-1 py-2 py-sm-0">
                <button
                  className="nav-link text-white fs-5"
                  aria-current="page"
                  onClick={handleLogout}
                >
                  <img
                    src="images/logout.png"
                    style={{
                      height: "20px",
                      filter: "invert(1)",
                      marginLeft: "-3",
                    }}
                    alt=""
                  />
                  <span className="ms-3 d-none d-sm-inline">Log Out</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="col p-md-2">
          {visibleSection === "Pending " && (
            <>
              <div className="p-0 p-md-3 mt-5" id="Pending -list">
                <div
                  className="date-picker d-flex justify-content-end mx-3"
                  style={{
                    alignItems: "center",
                  }}
                >
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    isClearable
                    placeholderText="Start Date"
                  />
                  &nbsp; &nbsp;
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    isClearable
                    placeholderText="End Date"
                  />
                </div>

                <DataTable
                  title="Pending List"
                  columns={columnsToShow}
                  data={loading ? [] : filteredAndSortedData}
                  // data={pendingReservations}
                  pagination
                  noDataComponent={<NoDataComponent />}
                  customStyles={customStyles}
                  highlightOnHover
                  pointerOnHover
                />

                <Drawer
                  anchor="right"
                  open={isDrawerOpen}
                  onClose={() => setIsDrawerOpen(false)}
                  PaperProps={{
                    style: {
                      width: isMobile ? "80%" : "30%",
                    },
                  }}
                >
                  <Typography
                    variant="h5"
                    component="h2"
                    // style={{ marginBottom: "5px" }}
                    className="p-3"
                  >
                    Details
                  </Typography>
                  <hr
                    className="text-secondary m-0"
                    style={{ width: "100%" }}
                  />
                  <List>
                    {/* <hr className="text-secondary" style={{ width: "100%" }} /> */}
                    {selectedReservation?.studentid && (
                      <ListItem sx={{pb: 0}}>
                        <ListItemText
                          // className="d-flex flex-row"
                          primary="Student ID :"
                          secondary={selectedReservation?.studentid}
                        />
                      </ListItem>
                    )}

                    {selectedReservation?.studentname && (
                      <ListItem sx={{pb: 0}}>
                        <ListItemText
                          primary="Student Name :"
                          secondary={selectedReservation?.studentname}
                        />
                      </ListItem>
                    )}

                    {selectedReservation?.studentemail && (
                      <ListItem sx={{pb: 0}}>
                        <ListItemText
                          primary="Student Email :"
                          secondary={selectedReservation?.studentemail}
                        />
                      </ListItem>
                    )}

                    {selectedReservation?.studentclass && (
                      <ListItem sx={{pb: 0}}>
                        <ListItemText
                          primary="Class :"
                          secondary={selectedReservation?.studentclass}
                        />
                      </ListItem>
                    )}

                    {selectedReservation?.mobile &&
                      (
                        <ListItem sx={{pb: 0}}>
                          <ListItemText
                            primary="Mobile No :"
                            secondary={selectedReservation?.mobile}
                          />
                        </ListItem>
                      )}

                    <ListItem sx={{pb: 0}}>
                      <ListItemText
                        primary="Date :"
                        secondary={
                          selectedReservation
                            ? formatDate(selectedReservation.reservationdate)
                            : "No date"
                        }
                      />
                    </ListItem>

                    <ListItem sx={{pb: 0}}>
                      <ListItemText
                        primary="Time :"
                        secondary={`${formatTime24To12(
                          selectedReservation?.time?.from
                        )} - ${formatTime24To12(
                          selectedReservation?.time?.to
                        )}`}
                      />
                    </ListItem>

                    {selectedReservation?.occasion && (
                      <ListItem sx={{pb: 0}}>
                        <ListItemText
                          primary="Occasion :"
                          secondary={selectedReservation?.occasion}
                        />
                      </ListItem>
                    )}

                    {selectedReservation?.numberOfPeople && (
                      <ListItem sx={{pb: 0}}>
                        <ListItemText
                          primary="Number Of People :"
                          secondary={selectedReservation?.numberOfPeople}
                        />
                      </ListItem>
                    )}

                    {selectedReservation?.note && (
                      <ListItem sx={{pb: 0}}>
                        <ListItemText
                          primary="Note :"
                          secondary={selectedReservation?.note}
                        />
                      </ListItem>
                    )}

                    <ListItem sx={{pb: 0}}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handleApproveReject(
                            selectedReservation?._id,
                            "approve"
                          )
                        }
                        style={{ backgroundColor: "#bc8d4b" }}
                      >
                        Approve
                      </Button>
                      <Button
                        // variant="outlined"
                        // color="secondary"
                        onClick={() =>
                          handleApproveReject(
                            selectedReservation?._id,
                            "reject"
                          )
                        }
                        style={{
                          marginLeft: "10px",
                          color: "#bc8d4b",
                          border: "1px solid #bc8d4b",
                        }}
                      >
                        Reject
                      </Button>
                    </ListItem>
                  </List>
                </Drawer>
              </div>

              <Dialog
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                classes={{ paper: "dialogPaper" }}
              >
                <DialogTitle className="dialogTitle">
                  Edit Reservation
                </DialogTitle>
                <DialogContent className="dialogContent">
                  <TextField
                    // label="New Date"
                    type="date"
                    value={newDate}
                    onChange={(e) => setNewDate(e.target.value)}
                    fullWidth
                    className="fullWidth textField"
                  />
                  <div className="flexRow">
                    <TextField
                      // label="From Time"
                      type="time"
                      value={newTimeFrom}
                      onChange={(e) => setNewTimeFrom(e.target.value)}
                      className="textField"
                    />
                    <TextField
                      // label="To Time"
                      type="time"
                      value={newTimeTo}
                      onChange={(e) => setNewTimeTo(e.target.value)}
                      fullWidth
                      className="textField"
                    />
                  </div>
                </DialogContent>
                <DialogActions className="dialogActions">
                  <Button
                    onClick={() => setEditModalOpen(false)}
                    className="cancelButton"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => submitEdit()}
                    color="primary"
                    className="submitButton"
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}

          {visibleSection === "Approved" && <Reserved />}

          {visibleSection === "History" && <AllHistory />}
        </div>
      </div>
    </div>
  );
};

export default SidebarMenu;
