// ProtectedRoute component
// import React from "react";
// import { Navigate } from "react-router-dom";
// import useAuth from "../hooks/useAuth"; // Adjust the import path as needed

// const ProtectedRoute = ({ children }) => {
//   const isAuthenticated = useAuth();

//   if (!isAuthenticated) {
//     // User not authenticated, redirect to login page
//     return <Navigate to="/reservation" replace />;
//   }

//   return children;
// };

// export default ProtectedRoute;

import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, role }) => {
  const isLoggedIn = localStorage.getItem("loginSuccess") === "true";
  const userRole = localStorage.getItem("userRole");

  console.log('role :>> ', role);
  console.log('userRole :>> ', userRole);

  if (!isLoggedIn) {
    return <Navigate to="/reservation" />;
  }

  if (userRole !== "admin") {
    return <Navigate to="/admin" />;
  }

  return children;
};

export default ProtectedRoute;
